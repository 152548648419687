import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import LoadingToast from '@/components/LoadingToast.vue'
import AuthService from '@/services/AuthService'
import {onMounted} from 'vue'
import {useStorage} from '@/services/stores/Storage'
import {useLoadingState} from '@/services/stores/LoadingState'
import {storeToRefs} from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const {getLogoUrl} = useStorage()
const {isLoading} = storeToRefs(useLoadingState())

onMounted((): void => {
  AuthService.onAuthStateChanged(location.pathname)
  getLogoUrl()
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view),
    _createVNode(LoadingToast, { isLoading: _unref(isLoading) }, null, 8, ["isLoading"])
  ]))
}
}

})