import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, mergeProps as _mergeProps, vModelDynamic as _vModelDynamic, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md col-xl-1 px-1" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["value", "selected"]
const _hoisted_7 = { class: "is-invalid" }
const _hoisted_8 = { class: "col-12 col-md col-xl-1 px-1" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "col-12 col-md px-1" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "col-12 col-md px-1" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_16 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_17 = { class: "is-invalid" }
const _hoisted_18 = { class: "col-12 col-md px-1" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "col-12 col-md px-1" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_23 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_24 = { class: "is-invalid" }
const _hoisted_25 = { class: "col-12 col-md px-1" }
const _hoisted_26 = { class: "row row-cols-2" }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { class: "form-group" }
const _hoisted_29 = ["value"]
const _hoisted_30 = { class: "col px-1" }
const _hoisted_31 = {
  class: "btn btn-primary d-inline-flex",
  type: "submit"
}

import {Field, Form, FormActions, ErrorMessage} from 'vee-validate'
import * as yup from 'yup'
import Service from '@/models/Service'
import AutoComplete from '@/components/AutoComplete.vue'
import {AutoCompleteType} from '@/types/AutoCompleteType'
import ClientRepository from '@/repositories/ClientRepository'
import {LocationType} from '@/types/LocationType'
import {ServiceInterface} from '@/types/ServiceInterface'
import {onMounted, ref, Ref, watch} from 'vue'
import ServiceRepository from '@/repositories/ServiceRepository'
import ToastService from '@/services/ToastService'
import i18n from '@/plugins/i18n'
import {ClientInterface} from '@/types/ClientInterface'
import {usePlacesStore} from '@/services/stores/PlacesStore'
import {useClientsStore} from '@/services/stores/ClientsStore'
import {PlaceInterface} from '@/types/PlaceInterface'
import {useLoadingState} from '@/services/stores/LoadingState'
import {storeToRefs} from 'pinia'
import {CountryCodeType} from '@/types/CountryCodeType'
import {StrHelper} from '@/helpers/StrHelper'
import {useWpClientsStore} from "@/services/stores/WpClientStore";
import AuthService from '@/services/AuthService'
import {useSettingsStore} from "@/services/stores/SettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateService',
  setup(__props) {

const placesAutocomplete: Ref<Array<AutoCompleteType>> = ref([])
const {places, findByName} = usePlacesStore()
const {clients, findById, updateClient} = useClientsStore()
const clientsPhone: Ref<Array<AutoCompleteType>> = ref([])
let start_loc: LocationType
const service: Ref<Partial<Service>> = ref(new Service())
const {setLoading} = useLoadingState()
const {countryCodes} = storeToRefs(useClientsStore())
const countryCode: Ref<CountryCodeType> = ref(countryCodes.value[0])
const count: Ref<number> = ref(1)
const {clients: wpClients, defaultClient} = storeToRefs(useWpClientsStore())
const {branchSelected} = useSettingsStore()

watch(clients, (newClients) => {
  updateAutocompleteClients(newClients)
})

watch(places, (newPlaces) => {
  updateAutocompletePlaces(newPlaces)
})

watch(() => service.value.name, (name) => {
  service.value.name = StrHelper.toCamelCase(name?? '')
})

onMounted(async () => {
  countryCode.value = countryCodes.value.filter(code => code.dialCode === branchSelected?.calling_code)[0]
  const input = document.querySelector('input[name="phone"]') as HTMLInputElement
  input?.focus()
  updateAutocompletePlaces(places)
  updateAutocompleteClients(clients)
})

function updateAutocompletePlaces(from: Array<PlaceInterface>): void {
  placesAutocomplete.value = []
  from.forEach(place => {
    placesAutocomplete.value.push({
      id: place.key?? '0',
      value: place.name
    })
  })
}

function updateAutocompleteClients(from: Array<ClientInterface>): void {
  clientsPhone.value = []
  from.forEach(clientDB => {
    clientsPhone.value.push({
      id: clientDB.id,
      value: clientDB.phone
    })
  })
}

const schema = yup.object().shape({
  wp_client_id: yup.string().required().min(9),
  name: yup.string().required().min(3),
  phone: yup.string().required().min(9).max(10),
  start_address: yup.string().required(),
  comment: yup.string().nullable()
})

function submitFromEnter(event: Event) {
  const input = document.querySelector('input[name="comment"]') as HTMLInputElement
  if(input !== document.activeElement) event.preventDefault()
}

async function onSubmit(values: ServiceInterface, event: FormActions<any>): Promise<void> {
  if (!start_loc) {
    await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), i18n.global.t('services.messages.no_start_loc'))
    return
  }
  if (!values.client_id || findById(values.client_id).name !== values.name) {
    setLoading(true)
    await createClient({
      id: '',
      name: values.name,
      phone: values.phone
    }).then((client) => {
      setLoading(false)
      ToastService.toast(ToastService.SUCCESS, i18n.global.t('services.messages.new_client'))
      values.client_id = client.id
      if (!findById(values.client_id)) {
        clientsPhone.value.push({
          id: client.id,
          value: client.phone
        })
      } else {
        updateClient(client)
      }
    }).catch(e => {
      setLoading(false)
      ToastService.toast(ToastService.ERROR,  i18n.global.t('common.messages.error'), e.message)
    })
  }

  createService(values)
  event.resetForm()
}

function createService(values: ServiceInterface): void {
  setLoading(true)
  if (values.created_by === undefined) {
    values.created_by = AuthService.getCurrentUser()?.id ?? null;
  }
  const newService: Service = new Service()
  newService.comment = values.comment ?? null
  newService.client_id = values.client_id
  newService.name = values.name
  newService.phone = values.phone
  newService.start_loc = start_loc
  newService.wp_client_id = values.wp_client_id
  newService.created_by = values.created_by
  ServiceRepository.create(newService, count.value).then(() => {
    setLoading(false)
    count.value = 1
    countryCode.value = countryCodes.value[31]
    service.value.wp_client_id = defaultClient.value as string
    ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.created'))
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR,  i18n.global.t('common.messages.error'), e.message)
  })
}

function onClientSelected(element: AutoCompleteType): void {
  let client = findById(element.id)
  service.value.phone = client.phone
  service.value.name = client.name
  service.value.client_id = client.id
  const input = document.querySelector('input[name="start_address"]') as HTMLInputElement
  input?.focus()
}

function createClient(client: ClientInterface): Promise<ClientInterface> {
  client.id = countryCode.value.dialCode.replace(/\D/g, "").concat(client.phone).concat('@c.us')
  return ClientRepository.create(client)
}

function checkPhoneNoExists(phone: string) {
  if (phone.length > 3) {
    const phoneExists = clientsPhone.value.some(client => client.value.includes(phone))
    if (!phoneExists) {
      service.value.name = i18n.global.t('common.models.users')
    }
  }
}

function locSelected(element: AutoCompleteType): void {
  let place = findByName(element.value)
  start_loc = { name: place.name, lat: place.lat, lng: place.lng}
  const input = document.querySelector('input[name="comment"]') as HTMLInputElement
  input?.focus()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Form), {
        onSubmit: onSubmit,
        "validation-schema": _unref(schema),
        autocomplete: "off",
        onKeydown: _withKeys(submitFromEnter, ["enter"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(Field), {
                  name: "wp_client_id",
                  class: "form-select pe-0",
                  modelValue: service.value.wp_client_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((service.value.wp_client_id) = $event)),
                  as: "select"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(wpClients), (client) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: client.id,
                        value: client.id,
                        selected: client.id == _unref(defaultClient)
                      }, _toDisplayString(client.alias), 9, _hoisted_6))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), { name: "wp_client_id" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(message), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("select", {
                  name: "countryCode",
                  class: "form-select pe-0",
                  id: "color",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((countryCode).value = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(countryCodes), (cCode, key) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: key,
                      value: cCode
                    }, _toDisplayString(cCode.dialCode + ' ' + cCode.code), 9, _hoisted_10))
                  }), 128))
                ], 512), [
                  [_vModelSelect, countryCode.value]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(AutoComplete, {
                  fieldName: 'phone',
                  idField: service.value.id,
                  onSelected: onClientSelected,
                  onOnChange: checkPhoneNoExists,
                  elements: clientsPhone.value,
                  modelValue: service.value.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((service.value.phone) = $event)),
                  placeholder: _ctx.$t('common.placeholders.phone'),
                  normalizer: _unref(StrHelper).formatNumber
                }, null, 8, ["idField", "elements", "modelValue", "placeholder", "normalizer"]),
                _createVNode(_unref(Field), {
                  name: "client_id",
                  type: "hidden",
                  modelValue: service.value.client_id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((service.value.client_id) = $event))
                }, {
                  default: _withCtx(({ field }) => [
                    _createElementVNode("input", _mergeProps({
                      type: "hidden",
                      name: "client_id"
                    }, field), null, 16)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_unref(Field), {
                  name: "name",
                  type: "text",
                  modelValue: service.value.name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((service.value.name) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('common.placeholders.name')
                    }, field, { autocomplete: "off" }), null, 16, _hoisted_15), [
                      [_vModelDynamic, field.value]
                    ]),
                    (errorMessage || !meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), { name: "name" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(message), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(AutoComplete, {
                  idField: service.value.id + 1,
                  fieldName: 'start_address',
                  onSelected: locSelected,
                  elements: placesAutocomplete.value,
                  placeholder: _ctx.$t('common.placeholders.address')
                }, null, 8, ["idField", "elements", "placeholder"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_unref(Field), {
                  name: "comment",
                  type: "text",
                  modelValue: service.value.comment,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((service.value.comment) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('common.placeholders.comment')
                    }, field, { autocomplete: "none" }), null, 16, _hoisted_22), [
                      [_vModelDynamic, field.value]
                    ]),
                    errorMessage
                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), { name: "comment" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("span", _hoisted_24, _toDisplayString(message), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _withDirectives(_createElementVNode("select", {
                      name: "countryCode",
                      class: "form-select pe-0",
                      id: "color",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((count).value = $event))
                    }, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (count) => {
                        return _createElementVNode("option", {
                          key: count,
                          value: count
                        }, _toDisplayString(count), 9, _hoisted_29)
                      }), 64))
                    ], 512), [
                      [_vModelSelect, count.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("button", _hoisted_31, _toDisplayString(_ctx.$t('common.actions.create')), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["validation-schema"])
    ])
  ]))
}
}

})