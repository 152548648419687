import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "driverModal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "modal-title",
  id: "AssignModalLabel"
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }
const _hoisted_8 = {
  type: "button",
  class: "btn btn-secondary",
  "data-bs-dismiss": "modal"
}

import {onMounted, ref, Ref, watchEffect} from 'vue'
import AutoComplete from '@/components/AutoComplete.vue'
import {AutoCompleteType} from '@/types/AutoCompleteType'
import Driver from '@/models/Driver'
import ToastService from '@/services/ToastService'
import {useI18n} from 'vue-i18n'
import Service from '@/models/Service'
import ServiceRepository from '@/repositories/ServiceRepository'
import {Modal} from 'bootstrap'
import {useLoadingState} from '@/services/stores/LoadingState'


export default /*@__PURE__*/_defineComponent({
  __name: 'AssingDriver',
  props: {
    drivers: {}
  },
  setup(__props: any) {

const props = __props
const plates: Ref<Array<AutoCompleteType>> = ref([])
const plate: Ref<string> = ref('')
const keyAutoComplete: Ref<number> = ref(0)
const {setLoading} = useLoadingState()
let service: Service = new Service()
let driverId: string|null
let driverModal: Modal
const {t} = useI18n()

watchEffect(async () => {
  props.drivers.forEach(driver => {
    if (driver.id) plates.value.push({id: driver.id, value: driver.vehicle.plate})
  })
})

onMounted(() => {
  const element = document.getElementById('driverModal') as HTMLElement;
  if (element) driverModal = new Modal(element)
  element?.addEventListener('show.bs.modal', (event: any) => {
    plate.value = ''
    const serviceId = event.relatedTarget.id
    if (serviceId) {
      ServiceRepository.getService(serviceId).then(dbService => {
        Object.assign(service, dbService)
      })
    }
  })

  element?.addEventListener('hidden.bs.modal', () => {
    plate.value = ''
  })
})

const assignDriver = (): void => {
  if (!driverId) {
    ToastService.toast(ToastService.ERROR, t('common.messages.error'), t('validations.driver'))
    return
  }
  service.driver_id = driverId
  service.status = Service.STATUS_IN_PROGRESS
  setLoading(true)
  service.assign(driverId).then(() => {
    setLoading(false)
    ToastService.toast(ToastService.SUCCESS, t('common.messages.updated'))
    driverModal?.hide()
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, t('common.messages.error'), e.message)
  })
}

function onDriverSelected(element: AutoCompleteType): void {
  driverId = element.id
  assignDriver()
  keyAutoComplete.value++
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('common.actions.assign')), 1),
          _cache[0] || (_cache[0] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createBlock(AutoComplete, _mergeProps({ idField: 'fieldAssign' }, plate.value, {
            elements: plates.value,
            onSelected: onDriverSelected,
            key: keyAutoComplete.value,
            placeholder: _ctx.$t('drivers.placeholders.plate'),
            fieldName: 'randomName'
          }), null, 16, ["elements", "placeholder"]))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('common.actions.close')), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: assignDriver
          }, _toDisplayString(_ctx.$t('common.actions.assign')), 1)
        ])
      ])
    ])
  ]))
}
}

})