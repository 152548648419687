import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card px-2 py-1" }
const _hoisted_2 = { class: "card-header pb-0" }
const _hoisted_3 = { class: "card-body px-0 pt-0 pb-2" }
const _hoisted_4 = { class: "table-responsive p-0" }
const _hoisted_5 = { class: "table align-items-center mb-0" }
const _hoisted_6 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_8 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_9 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_10 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_11 = { class: "align-middle" }
const _hoisted_12 = { class: "align-middle text-truncate text-nowrap text-MaxErm" }
const _hoisted_13 = { class: "align-middle text-truncate text-nowrap text-MaxErm" }
const _hoisted_14 = { class: "align-middle p-0" }
const _hoisted_15 = { class: "row row-cols-2 mx-2" }
const _hoisted_16 = { class: "form-check form-switch col-2" }
const _hoisted_17 = ["checked", "onChange"]
const _hoisted_18 = { class: "align-middle" }
const _hoisted_19 = ["data-bs-target"]
const _hoisted_20 = { class: "align-middle" }
const _hoisted_21 = { class: "align-middle text-truncate text-nowrap text-MaxErm" }
const _hoisted_22 = { class: "align-middle text-truncate text-nowrap text-MaxErm" }
const _hoisted_23 = { class: "align-middle p-0" }
const _hoisted_24 = { class: "row row-cols-2 mx-2" }
const _hoisted_25 = { class: "form-check form-switch col-2" }
const _hoisted_26 = ["checked", "onChange"]
const _hoisted_27 = { class: "align-middle" }
const _hoisted_28 = ["data-bs-target"]

import {computed, onMounted, ref} from 'vue'
import SettingsRepository from '@/repositories/SettingsRepository'
import {SettingsMessageInterface} from '@/types/SettingsMessagesInterface'
import {useLoadingState} from '@/services/stores/LoadingState'
import ToastService from '@/services/ToastService'
import i18n from '@/plugins/i18n'
import EditModal from '@/views/settings/messages/Edit.vue'
import {Constants} from '@/constants/Constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const messages = ref<SettingsMessageInterface[]>([])
const { setLoading } = useLoadingState()

onMounted(async () => {
  await updateMessages()
})

const updateMessages = async () => {
  setLoading(true)
  messages.value = await SettingsRepository.getMessages().finally(() => setLoading(false))
}

const confirmationMessages = computed(() => {
  return messages.value.filter((message) => {
    return Constants.CONFIRMATIONS.includes(message.id)
  })
})

const chatBotMessages = computed(() => {
  return messages.value.filter((message) => {
    return !Constants.CONFIRMATIONS.includes(message.id)
  })
})

const toggleMessage = async (message: SettingsMessageInterface): Promise<void> => {
  setLoading(true);
  message.enabled = !message.enabled
  SettingsRepository.updateMessage(message)
    .then(async () => {
      setLoading(false)
      const statusMessage = message.enabled
        ? i18n.global.t('common.fields.enabled')
        : i18n.global.t('common.fields.disabled')
      await ToastService.toast(ToastService.SUCCESS, statusMessage)
    })
    .catch(async (error) => {
      setLoading(false);
      await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), error.message)
    })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('common.titles.title_card')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("caption", { hidden: "" }, null, -1)),
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('services.fields.name')), 1),
              _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('services.fields.comment')), 1),
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('services.fields.WpMessages')), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('common.fields.status')), 1),
              _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('services.fields.WpActions')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('wp.titles.confirmations_messages')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(confirmationMessages.value, (message, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createVNode(EditModal, {
                  selectedMessage: message,
                  onUpdateMessages: updateMessages
                }, null, 8, ["selectedMessage"]),
                _createElementVNode("td", _hoisted_11, _toDisplayString(message.name), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(message.description), 1),
                _createElementVNode("td", _hoisted_13, _toDisplayString(message.message), 1),
                _createElementVNode("td", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("input", {
                        class: "form-check-input",
                        name: "enable",
                        type: "checkbox",
                        checked: message.enabled,
                        onChange: ($event: any) => (toggleMessage(message))
                      }, null, 40, _hoisted_17),
                      _createElementVNode("span", {
                        class: _normalizeClass(["badge badge-sm", message.enabled ? 'bg-gradient-success' : 'bg-gradient-danger'])
                      }, _toDisplayString(_ctx.$t(message.enabled ?
                      'common.fields.enabled' : 'common.fields.disabled')), 3)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("button", {
                    class: "btn btn-sm btn-info btn-rounded rounded-pill py-1 m-0",
                    "data-bs-toggle": "modal",
                    "data-bs-target": '#' + message.id
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                  ]), 8, _hoisted_19)
                ])
              ]))
            }), 128)),
            _createElementVNode("tr", null, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('wp.titles.chatbot_messages')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatBotMessages.value, (message, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createVNode(EditModal, {
                  selectedMessage: message,
                  onUpdateMessages: updateMessages
                }, null, 8, ["selectedMessage"]),
                _createElementVNode("td", _hoisted_20, _toDisplayString(message.name), 1),
                _createElementVNode("td", _hoisted_21, _toDisplayString(message.description), 1),
                _createElementVNode("td", _hoisted_22, _toDisplayString(message.message), 1),
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("input", {
                        class: "form-check-input",
                        name: "enable",
                        type: "checkbox",
                        checked: message.enabled,
                        onChange: ($event: any) => (toggleMessage(message))
                      }, null, 40, _hoisted_26),
                      _createElementVNode("span", {
                        class: _normalizeClass(["badge badge-sm", message.enabled ? 'bg-gradient-success' : 'bg-gradient-danger'])
                      }, _toDisplayString(_ctx.$t(message.enabled ?
                        'common.fields.enabled' : 'common.fields.disabled')), 3)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_27, [
                  _createElementVNode("button", {
                    class: "btn btn-sm btn-info btn-rounded rounded-pill py-1 m-0",
                    "data-bs-toggle": "modal",
                    "data-bs-target": '#' + message.id
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                  ]), 8, _hoisted_28)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})