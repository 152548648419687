import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-fluid pb-4"
}
const _hoisted_2 = { class: "card w-75 mx-auto" }
const _hoisted_3 = { class: "card-header text-center text-capitalize" }
const _hoisted_4 = { class: "card-body pt-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "card-header p-0 mx-3 mt-3 z-index-1" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col-md-7" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-control-label" }
const _hoisted_12 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-control-label" }
const _hoisted_16 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_18 = { class: "form-group editLink" }
const _hoisted_19 = {
  class: "button-link",
  href: "#",
  id: "openEditPasswordModalButton",
  "data-bs-toggle": "modal",
  "data-bs-target": "#editPassword"
}
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "form-control-label" }
const _hoisted_22 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_23 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = { class: "form-control-label" }
const _hoisted_26 = { class: "form-check mb-4 d-inline-block ms-4" }
const _hoisted_27 = ["checked"]
const _hoisted_28 = { class: "custom-control-label" }
const _hoisted_29 = { class: "form-check d-inline-block ms-3" }
const _hoisted_30 = ["checked"]
const _hoisted_31 = { class: "custom-control-label" }
const _hoisted_32 = { class: "form-check form-switch" }
const _hoisted_33 = ["checked"]
const _hoisted_34 = { class: "form-check-label" }
const _hoisted_35 = { class: "card-footer text-end" }
const _hoisted_36 = {
  class: "btn btn-info",
  type: "submit"
}
const _hoisted_37 = {
  class: "modal fade",
  id: "editPassword",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_38 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_39 = { class: "modal-content" }
const _hoisted_40 = { class: "modal-header" }
const _hoisted_41 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_42 = { class: "modal-body" }
const _hoisted_43 = { class: "mb-3" }
const _hoisted_44 = { class: "input-group" }
const _hoisted_45 = ["placeholder", "type"]
const _hoisted_46 = { class: "modal-footer" }
const _hoisted_47 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_48 = {
  type: "submit",
  class: "btn bg-gradient-primary"
}

import UserRepository from '@/repositories/UserRepository'
import User from '@/models/User'
import {ErrorMessage, Field, Form} from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import * as bootstrap from 'bootstrap'
import StorageService from '@/services/StorageService'
import dayjs from 'dayjs'
import i18n from '@/plugins/i18n'
import ToastService from '@/services/ToastService'
import {onBeforeMount, ref, Ref} from 'vue'
import {useRoute} from 'vue-router'
import {useLoadingState} from '@/services/stores/LoadingState'
import { hide } from '@/helpers/ModalHelper'
import ImageLoader from "@/components/ImageLoader.vue";

const userEvent = 'image-user-loaded'

export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  setup(__props) {

const user: Ref<User> = ref(new User)
const route = useRoute()
const showPassword = ref(false);
const {setLoading} = useLoadingState()
const schema = yup.object().shape({
  name: yup.string().required().min(3),
  email: yup.string().required().email(),
  phone: yup.string().required().min(8),
  role: yup.array()
})

const schemaPassword = yup.object().shape({
  password: yup.string().required()
})

function uploadImg(url: string): void {
  user.value.photoUrl = url
  updateUser()
}

function updateUser(): void {
  setLoading(true)
  UserRepository.update(user.value).then(() => {
    setLoading(false)
    Swal.fire({
      icon: 'success',
      title: i18n.global.t('common.messages.updated'),
      showConfirmButton: false,
      timer: 1500
    })
    const modal = document.getElementById('image-user')
    const modalImg = bootstrap.Modal.getOrCreateInstance(modal ?? '')
    modalImg.hide()
  }).catch(e => {
    setLoading(false)
    Swal.fire({
      icon: 'error',
      title: i18n.global.t('common.messages.error'),
      text: e.message
    })
  })
}

function onEnable(event: Event): void {
  setLoading(true)
  const target = event.target as HTMLInputElement
  user.value.enabled_at = target.checked ? dayjs().unix() : 0
  UserRepository.enable(user.value.id ?? '', user.value.enabled_at).then(async () => {
    setLoading(false)
    const message = user.value.enabled_at == 0 ?
        i18n.global.t('users.messages.disabled') :
        i18n.global.t('users.messages.enabled')
    await ToastService.toast(ToastService.SUCCESS, message)
  }).catch(async e => {
    setLoading(false)
    await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function updatePassword(): void {
  setLoading(true)
  UserRepository.updatePassword(user.value.id ?? '', user.value.password).then(async () => {
    setLoading(false)
    hide('editPassword')
    await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
   await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function assignRole(e: Event): void {
  const target = e.target as HTMLInputElement
  if (user.value.roles) {
    if (target.value === 'operator') {
      user.value.roles.operator = target.checked
    } else {
      user.value.roles.admin = target.checked
    }
  }
}

onBeforeMount(async () => {
  const userDB = await UserRepository.getUser(route.params.id as string)
    Object.assign(user.value, userDB)
    user.value.photoUrl = user.value.photoUrl ?? '../../assets/img/logo.png'
})

return (_ctx: any,_cache: any) => {
  return (user.value.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(Form), {
          onSubmit: updateUser,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.$t('users.forms.edit')), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("img", {
                        src: user.value.photoUrl,
                        class: "img-fluid border-radius-lg",
                        alt: "profile_photo"
                      }, null, 8, _hoisted_8),
                      _cache[5] || (_cache[5] = _createElementVNode("button", {
                        class: "btn btn-sm btn-icon btn-2 btn-primary btn-edit-img",
                        type: "button",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#image-user"
                      }, [
                        _createElementVNode("span", { class: "btn-inner--icon" }, [
                          _createElementVNode("em", { class: "fas fa-pen" })
                        ])
                      ], -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('users.fields.name')), 1),
                      _createVNode(_unref(Field), {
                        name: "name",
                        type: "text",
                        modelValue: user.value.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user.value.name) = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('common.placeholders.name'),
                            id: "name",
                            "aria-label": "Name",
                            "aria-describedby": "name-addon"
                          }, field), null, 16, _hoisted_12), [
                            [_vModelDynamic, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('users.fields.email')), 1),
                      _createVNode(_unref(Field), {
                        name: "email",
                        type: "email",
                        modelValue: user.value.email,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.value.email) = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('common.placeholders.email'),
                            id: "email",
                            "aria-label": "Email",
                            "aria-describedby": "email-addon"
                          }, field), null, 16, _hoisted_16), [
                            [_vModelDynamic, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t('common.placeholders.restore_password')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('users.fields.phone')), 1),
                      _createVNode(_unref(Field), {
                        name: "phone",
                        type: "phone",
                        modelValue: user.value.phone,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((user.value.phone) = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('common.placeholders.phone'),
                            id: "phone",
                            "aria-label": "Phone",
                            "aria-describedby": "phone-addon"
                          }, field), null, 16, _hoisted_22), [
                            [_vModelDynamic, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('users.fields.role')) + ":", 1),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("input", {
                          class: "form-check-input",
                          type: "checkbox",
                          onChange: assignRole,
                          name: "role",
                          id: "operator",
                          value: 'operator',
                          checked: user.value.roles.operator
                        }, null, 40, _hoisted_27),
                        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('users.fields.operator')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("input", {
                          class: "form-check-input",
                          type: "checkbox",
                          name: "role",
                          onChange: assignRole,
                          id: "admin",
                          value: 'admin',
                          checked: user.value.roles.admin
                        }, null, 40, _hoisted_30),
                        _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('users.fields.admin')), 1)
                      ]),
                      _createVNode(_unref(ErrorMessage), { name: "role" })
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("input", {
                        class: "form-check-input",
                        name: "enable",
                        type: "checkbox",
                        id: "flexSwitchCheckDefault",
                        checked: user.value.isEnabled(),
                        onChange: onEnable
                      }, null, 40, _hoisted_33),
                      _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t(user.value.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 1),
                      _createVNode(_unref(ErrorMessage), { name: "enable" })
                    ])
                  ])
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("button", _hoisted_36, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"]),
        _createVNode(ImageLoader, {
          id: 'image-user',
          resourceId: user.value.id,
          path: _unref(StorageService).profilePath,
          event: userEvent,
          onImageUserLoaded: uploadImg
        }, null, 8, ["resourceId", "path"]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("h5", _hoisted_41, _toDisplayString(_ctx.$t('users.forms.edit_password')), 1),
                _cache[7] || (_cache[7] = _createElementVNode("button", {
                  type: "button",
                  id: "closeEditPasswordModalButton",
                  class: "btn-close",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close"
                }, [
                  _createElementVNode("span", { "aria-hidden": "true" }, "×")
                ], -1))
              ]),
              _createVNode(_unref(Form), {
                onSubmit: updatePassword,
                "validation-schema": _unref(schemaPassword)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                      _createVNode(_unref(Field), {
                        name: "password",
                        type: "password"
                      }, {
                        default: _withCtx(({ field }) => [
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("span", {
                              style: {"cursor":"pointer"},
                              class: "input-group-text",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (showPassword.value = !showPassword.value))
                            }, [
                              _createElementVNode("i", {
                                class: _normalizeClass(["fa", showPassword.value ? 'fa-eye' : 'fa-eye-slash'])
                              }, null, 2)
                            ]),
                            _withDirectives(_createElementVNode("input", _mergeProps({
                              class: "form-control form-control-sm",
                              id: "password",
                              "aria-label": "Password",
                              "aria-describedby": "password-addon",
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((user.value.password) = $event)),
                              placeholder: _ctx.$t('common.placeholders.password')
                            }, field, {
                              type: showPassword.value ? 'text' : 'password'
                            }), null, 16, _hoisted_45), [
                              [_vModelDynamic, user.value.password]
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(ErrorMessage), { name: "password" })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("button", _hoisted_47, _toDisplayString(_ctx.$t('common.actions.close')), 1),
                    _createElementVNode("button", _hoisted_48, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["validation-schema"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})