import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "me-2" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden" }
const _hoisted_4 = { class: "row gx-4" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "avatar avatar-xl position-relative" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "col-auto my-auto" }
const _hoisted_9 = { class: "h-100" }
const _hoisted_10 = { class: "mb-1" }
const _hoisted_11 = { class: "mb-0 font-weight-bold text-sm" }
const _hoisted_12 = { class: "container-fluid py-4" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12 col-xl-6" }
const _hoisted_15 = { class: "card h-100" }
const _hoisted_16 = { class: "card-header pb-0 p-3" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md-8 d-flex align-items-center" }
const _hoisted_19 = { class: "mb-0" }
const _hoisted_20 = { class: "card-body p-3" }
const _hoisted_21 = { class: "list-group" }
const _hoisted_22 = { class: "list-group-item border-0 ps-0 pt-0 text-sm" }
const _hoisted_23 = { class: "text-dark" }
const _hoisted_24 = { class: "list-group-item border-0 ps-0 text-sm" }
const _hoisted_25 = { class: "text-dark" }
const _hoisted_26 = { class: "list-group-item border-0 ps-0 text-sm" }
const _hoisted_27 = { class: "text-dark" }
const _hoisted_28 = { class: "col-12 col-xl-6" }
const _hoisted_29 = { class: "card h-100" }
const _hoisted_30 = { class: "card-header pb-0 p-3" }
const _hoisted_31 = { class: "mb-0" }
const _hoisted_32 = { class: "card-body p-3" }
const _hoisted_33 = { class: "text-uppercase text-body text-xs font-weight-bolder" }
const _hoisted_34 = { class: "list-group" }
const _hoisted_35 = {
  key: 0,
  class: "d-flex px-2 py-1"
}
const _hoisted_36 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_37 = { class: "mb-0 text-sm" }

import User from '@/models/User'
import AuthService from '@/services/AuthService'
import {onBeforeMount, ref, Ref} from 'vue'
import {useStorage} from '@/services/stores/Storage'
import {storeToRefs} from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'Profile',
  setup(__props) {

const user: Ref<User> = ref(new User())
const storage = useStorage()
const {logoUrl} = storeToRefs(storage)

onBeforeMount(() => {
  user.value = AuthService.getCurrentUser()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "page-header min-height-300 border-radius-xl mt-4",
        style: {"background-image":"url('../../assets/img/background.jpg')","background-position-y":"50%"}
      }, [
        _createElementVNode("span", { class: "mask bg-gradient-primary opacity-6" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                src: _unref(logoUrl),
                alt: "profile_image",
                class: "w-100 border-radius-lg shadow-sm"
              }, null, 8, _hoisted_7)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h5", _hoisted_10, _toDisplayString(user.value.name), 1),
              _createElementVNode("p", _hoisted_11, _toDisplayString(user.value.email), 1)
            ])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3" }, [
            _createElementVNode("div", { class: "nav-wrapper position-relative end-0" })
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("h6", _hoisted_19, _toDisplayString(_ctx.$t('users.profile')), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-md-4 text-end" }, [
                  _createElementVNode("a", { href: "javascript:;" }, [
                    _createElementVNode("em", {
                      class: "fas fa-user-edit text-secondary text-sm",
                      "data-bs-toggle": "tooltip",
                      "data-bs-placement": "top",
                      title: "Edit Profile"
                    })
                  ])
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-sm" }, null, -1)),
              _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "horizontal gray-light my-4" }, null, -1)),
              _createElementVNode("ul", _hoisted_21, [
                _createElementVNode("li", _hoisted_22, [
                  _createElementVNode("strong", _hoisted_23, _toDisplayString(_ctx.$t('users.fields.name')) + ": ", 1),
                  _createTextVNode("   " + _toDisplayString(user.value.name), 1)
                ]),
                _createElementVNode("li", _hoisted_24, [
                  _createElementVNode("strong", _hoisted_25, _toDisplayString(_ctx.$t('users.fields.email')) + ": ", 1),
                  _createTextVNode("   " + _toDisplayString(user.value.email), 1)
                ]),
                _createElementVNode("li", _hoisted_26, [
                  _createElementVNode("strong", _hoisted_27, _toDisplayString(_ctx.$t('users.fields.phone')) + ": ", 1),
                  _createTextVNode("   " + _toDisplayString(user.value.phone), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("h6", _hoisted_31, _toDisplayString(_ctx.$t('users.platform_settings')), 1)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("h6", _hoisted_33, _toDisplayString(_ctx.$t('users.fields.roles')), 1),
              _createElementVNode("ul", _hoisted_34, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.value.roles, (value, role) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-group-item border-0 px-0",
                    key: role
                  }, [
                    value
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _cache[5] || (_cache[5] = _createElementVNode("div", null, [
                            _createElementVNode("em", { class: "fas fa-tools fa-1x me-2" })
                          ], -1)),
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("h6", _hoisted_37, _toDisplayString(_ctx.$t('users.fields.' + role)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})