import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal bg-opacity",
  id: "dialogModal",
  tabindex: "-1",
  role: "dialog",
  "aria-labelledby": "exampleModalLongTitle",
  "aria-hidden": "true"
}
const _hoisted_2 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "modal-title",
  id: "modalTitle"
}

import {onMounted, watch} from 'vue'
import {Modal} from 'bootstrap'

interface Props {
  isLoading: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingToast',
  props: {
    isLoading: { type: Boolean }
  },
  setup(__props: any) {


const props = __props
let dialog: Modal

watch(props, (prop) => {
  if (!dialog) return

  if (prop.isLoading) {
    dialog.show()
  } else {
    dialog.hide()
  }
})

onMounted(() => {
  const element = document.getElementById('dialogModal') as HTMLElement;
  if (element) dialog = new Modal(element, {
    backdrop: false,
    keyboard: false
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('common.messages.waiting')), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "modal-body text-center py-5" }, [
          _createElementVNode("em", { class: "fa-solid fa-spinner fa-10x circle" })
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "modal-footer py-4" }, null, -1))
      ])
    ])
  ]))
}
}

})