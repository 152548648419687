import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "form-group mb-1" }
const _hoisted_2 = ["name", "id", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_4 = { class: "is-invalid" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["onClick", "id"]

import {Field, ErrorMessage} from 'vee-validate'
import {AutoCompleteType} from '@/types/AutoCompleteType'
import {onMounted, ref, Ref, watch} from 'vue'

interface Props {
  elements: Array<AutoCompleteType>
  classes?: string
  fieldName?: string
  placeholder?: string
  idField?: string
  normalizer?: (str: string) => string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AutoComplete',
  props: {
    elements: {},
    classes: {},
    fieldName: {},
    placeholder: {},
    idField: {},
    normalizer: { type: Function }
  },
  emits: ['on-change', 'selected'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const input = ref<HTMLInputElement | null>(null)
const foundElements: Ref<Array<AutoCompleteType>> = ref([])
const searchElement: Ref<string> = ref('')
const emit = __emit
const callback = function (mutationsList: MutationRecord[]) {
  for (let mutation of mutationsList) {
    if (mutation.type === 'childList') {
      const list = document?.getElementById('list-' + props.idField + props.fieldName)
      if (list && list.children.length > 0) {
        addListener()
      }
    }
  }
}

watch(searchElement, (newValue) => {
  if (props.normalizer) searchElement.value = props.normalizer(newValue)
})

onMounted(() => {
  const targetNode = document.body
  const config = {childList: true, subtree: true}
  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)
})

function addListener(): void {
  const ul = document.getElementById('list-' + props.idField + props.fieldName)
  let liSelected: HTMLLIElement | null
  let index = -1
  if (!ul) return
  document.addEventListener('keydown', function (event: KeyboardEvent) {
    const len = ul.getElementsByTagName('li')?.length - 1
    if (len <= -1) return
    switch (event.code) {
      case 'ArrowDown':
        index++
        if (liSelected) {
          removeClass(liSelected, 'selected')
          let next = ul.getElementsByTagName('li')[index] as HTMLLIElement
          if (next && index <= len) {

            liSelected = next
          } else {
            index = 0
            liSelected = ul.getElementsByTagName('li')[0] as HTMLLIElement
          }
          addClass(liSelected, ul, 'selected')
        } else {
          index = 0

          liSelected = ul.getElementsByTagName('li')[index] as HTMLLIElement
          if (liSelected) addClass(liSelected, ul, 'selected')
        }
        break
      case 'ArrowUp':
        if (liSelected) {
          removeClass(liSelected, 'selected')
          index--
          let next = ul.getElementsByTagName('li')[index] as HTMLLIElement
          if (next && index >= 0) {
            liSelected = next
          } else {
            index = len
            liSelected = ul.getElementsByTagName('li')[len] as HTMLLIElement
          }
          addClass(liSelected, ul, 'selected')
        } else {
          index = 0
          liSelected = ul.getElementsByTagName('li')[len] as HTMLLIElement
          if (liSelected) addClass(liSelected, ul, 'selected')
        }
        break
      case 'Enter':
        if (liSelected) {
          input.value?.blur()
          liSelected = ul.getElementsByClassName('selected')[0] as HTMLLIElement
          liSelected.click()
          liSelected.remove()
          liSelected.remove()
        }
        break
      default:
        return
    }
  }, false)
}

function onChange(): void {
  emit('on-change', searchElement.value)
}

function removeClass(el: HTMLLIElement, className: string) {
  if (!el.className.includes(className)) return
  if (el.classList) {
    el.classList.remove(className)
  } else {
    el.className = el.className
        .replace(new RegExp('(^|\\b)' + className.split(' ')
            .join('|') + '(\\b|$)', 'gi'), ' ')
  }
}

function addClass(el: HTMLLIElement, ul: HTMLElement, className: string) {
  const elements = ul.getElementsByClassName('selected')
  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      const li = elements.item(i) as HTMLLIElement
      removeClass(li, 'selected')
    }
  }
  if (el.className.includes(className)) return
  if (el.classList) {
    el.classList.add(className)
  } else {
    el.className += ' ' + className
  }
}

function searchElements(): void {
  let matches = 0
  if (searchElement.value.length > 2) {
    foundElements.value = props.elements.filter(element => {
      if (element.value.toLowerCase().includes(searchElement.value.toLowerCase()) && matches < 5) {
        matches++
        return element
      }
    })
  } else {
    foundElements.value = []
  }
}

function selectElement(element: AutoCompleteType): void {
  emit('selected', element, props.idField)
  searchElement.value = element.value
  foundElements.value = []
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Field), {
      name: props.fieldName ?? '12345',
      ref: input.value,
      modelValue: searchElement.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchElement).value = $event))
    }, {
      default: _withCtx(({ errorMessage, meta }) => [
        _withDirectives(_createElementVNode("input", {
          name: props.fieldName ?? '12345',
          id: _ctx.idField?? 'search',
          class: _normalizeClass(_ctx.classes?? 'form-control'),
          type: "text",
          onInput: onChange,
          placeholder: props.placeholder,
          autocomplete: "none",
          onKeyup: searchElements,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchElement).value = $event))
        }, null, 42, _hoisted_2), [
          [_vModelText, searchElement.value]
        ]),
        (errorMessage && meta.dirty)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(errorMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name", "modelValue"]),
    _createVNode(_unref(ErrorMessage), {
      name: props.fieldName?? '12345'
    }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"]),
    _withDirectives(_createElementVNode("ul", {
      class: "list-group autocomplete-list shadow-sm",
      id: 'list-' + props.idField + props.fieldName
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(foundElements.value, (element) => {
        return (_openBlock(), _createElementBlock("li", {
          key: element.id,
          onClick: ($event: any) => (selectElement(element)),
          class: "list-group-item",
          id: element.id
        }, _toDisplayString(element.value), 9, _hoisted_6))
      }), 128))
    ], 8, _hoisted_5), [
      [_vShow, foundElements.value.length > 0]
    ])
  ]))
}
}

})