import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-2" }
const _hoisted_2 = { class: "row mx-2 mt-2" }
const _hoisted_3 = { class: "col col-md-2 text-bold" }
const _hoisted_4 = {
  class: "card-body collapse show",
  id: "collapse-filter"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-2" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  class: "form-control-label",
  for: "from"
}
const _hoisted_9 = { class: "col-md-2" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  class: "form-control-label",
  for: "from"
}
const _hoisted_12 = { class: "col-md-2" }
const _hoisted_13 = {
  class: "form-control-label",
  for: "from"
}
const _hoisted_14 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_15 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_16 = { class: "col-md-2" }
const _hoisted_17 = {
  class: "form-control-label",
  for: "to"
}
const _hoisted_18 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_19 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_20 = { class: "col-md-4" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { class: "d-flex" }
const _hoisted_23 = {
  class: "btn btn-sm btn-primary mt-1 me-2",
  type: "submit",
  name: "submit"
}
const _hoisted_24 = { class: "row my-2" }
const _hoisted_25 = { class: "col-12 col-md-4 my-1 my-md-0" }
const _hoisted_26 = { class: "card" }
const _hoisted_27 = { class: "card-body p-3" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "col-9 col-sm-10" }
const _hoisted_30 = { class: "numbers" }
const _hoisted_31 = { class: "text-sm mb-0 text-capitalize font-weight-bold" }
const _hoisted_32 = { class: "font-weight-bolder mb-0" }
const _hoisted_33 = { class: "text-success text-sm font-weight-bolder" }
const _hoisted_34 = { class: "col-12 col-md-4 my-1 my-md-0" }
const _hoisted_35 = { class: "card" }
const _hoisted_36 = { class: "card-body p-3" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-9 col-sm-10" }
const _hoisted_39 = { class: "numbers" }
const _hoisted_40 = { class: "text-sm mb-0 text-capitalize font-weight-bold" }
const _hoisted_41 = { class: "font-weight-bolder mb-0" }
const _hoisted_42 = { class: "text-success text-sm font-weight-bolder" }
const _hoisted_43 = { class: "col-12 col-md-4 my-1 my-md-0" }
const _hoisted_44 = { class: "card" }
const _hoisted_45 = { class: "card-body p-3" }
const _hoisted_46 = { class: "row" }
const _hoisted_47 = { class: "col-9 col-sm-10" }
const _hoisted_48 = { class: "numbers" }
const _hoisted_49 = { class: "text-sm mb-0 text-capitalize font-weight-bold" }
const _hoisted_50 = { class: "font-weight-bolder mb-0" }
const _hoisted_51 = { class: "text-success text-sm font-weight-bolder" }

import ServicesTable from '@/components/services/ServicesTable.vue'
import {storeToRefs} from 'pinia'
import {useServicesStore} from '@/services/stores/ServiceStore'
import {Field, Form} from 'vee-validate'
import {date, object} from 'yup'
import {computed, onBeforeMount, ref, Ref, watch, watchEffect, nextTick} from 'vue'
import DateHelper from '@/helpers/DateHelper'
import {StrHelper} from '@/helpers/StrHelper'
import {Tables} from '@/constants/Tables'
import AutoComplete from '@/components/AutoComplete.vue'
import {useDriversStore} from '@/services/stores/DriversStore'
import {AutoCompleteType} from '@/types/AutoCompleteType'
import {useClientsStore} from '@/services/stores/ClientsStore'
import {ServiceCursor} from "@/types/ServiceCursor"
import Service from '@/models/Service'
import ShowServiceModal from '@/components/services/ShowServiceModal.vue'
import {Modal} from 'bootstrap'
import {ServiceList} from '@/models/ServiceList'


export default /*@__PURE__*/_defineComponent({
  __name: 'History',
  setup(__props) {


const { getHistoryServices, resetCursor } = useServicesStore()
const { history, pagination, completed, canceled, currentCursor } = storeToRefs(useServicesStore())
const { drivers } = useDriversStore()
const { clients } = useClientsStore()
const { filter } = storeToRefs(useServicesStore())
const plates: Ref<Array<AutoCompleteType>> = ref([])
const clientsPhone: Ref<Array<AutoCompleteType>> = ref([])
const plate: Ref<number> = ref(0)
const clientPhone: Ref<number> = ref(0)
let selectedService = ref<ServiceList|null>(null)

const schema = object().shape({
  from: date().required(),
  to: date().required()
})

const completedPercent = computed(() =>
  isWhatPercent(completed.value)
)

const canceledPercent = computed(() =>
  isWhatPercent(canceled.value)
)

watch(() => pagination.value.perPage, async () => {
  resetCursor()
  pagination.value.currentPage = 1
  await getHistoryServices()
})

watchEffect(async () => {
  drivers.forEach(driver => {
    if (driver.id) plates.value.push({ id: driver.id, value: driver.vehicle.plate })
  })

  clientsPhone.value = []
  clients.forEach(clientDB => {
    clientsPhone.value.push({
      id: clientDB.id,
      value: clientDB.phone
    })
  })
})

onBeforeMount(async () => {
  pagination.value.cursor = currentCursor.value
  await getHistoryServices(true, true)
})

function onDriverSelected(element: AutoCompleteType): void {
  filter.value.driverId = element.id
  const input = document.querySelector('input[name="driver"]') as HTMLInputElement
  input?.blur()
}

function onClientSelected(element: AutoCompleteType): void {
  filter.value.clientId = element.id
  const input = document.querySelector('input[name="client"]') as HTMLInputElement
  input?.blur()
}

async function clearFilters(): Promise<void> {
  filter.value.from = DateHelper.stringNow()
  filter.value.to = DateHelper.stringNow()
  filter.value.clientId = null
  filter.value.driverId = null
  clientPhone.value++
  plate.value++
  resetCursor()
  await getHistoryServices()
}

function isWhatPercent(x: number): number {
  return Math.round((x / (pagination.value.totalCount === 0 ? 1 : pagination.value.totalCount)) * 100)
}

async function getServices(): Promise<void> {
  resetCursor()
  await getHistoryServices()
}

async function show(service: Service): Promise<void> {
  selectedService.value = service
  await nextTick()
  const modalShow = document.getElementById('showServiceModal')
  const modal = new Modal(modalShow?? 'showServiceModal')
  modal.show()
}

async function paginateData(page: number, cursor: ServiceCursor, next: boolean): Promise<void> {
  pagination.value.cursor = cursor
  pagination.value.currentPage = page
  await getHistoryServices(next)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('common.filters.title')), 1),
        _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"col col-md-4\"></div><div class=\"col col-md-4\"></div><div class=\"col col-md-2 text-end\"><button class=\"btn btn-sm btn-light\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapse-filter\" aria-expanded=\"true\" aria-controls=\"collapse-filter\"><em class=\"fa-solid fa-bars\"></em></button></div>", 3))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(Form), {
          onSubmit: getServices,
          "validation-schema": _unref(schema),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"]))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('common.filters.driver_plate')), 1),
                  (_openBlock(), _createBlock(AutoComplete, {
                    idField: 'field-driver',
                    elements: plates.value,
                    onSelected: onDriverSelected,
                    placeholder: _ctx.$t('common.filters.driver_plate'),
                    fieldName: 'driver',
                    key: plate.value,
                    classes: 'form-control form-control-sm'
                  }, null, 8, ["elements", "placeholder"]))
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('common.filters.number_client')), 1),
                  (_openBlock(), _createBlock(AutoComplete, {
                    idField: 'field-client',
                    elements: clientsPhone.value,
                    onSelected: onClientSelected,
                    key: clientPhone.value,
                    placeholder: _ctx.$t('common.filters.number_client'),
                    fieldName: 'client',
                    normalizer: _unref(StrHelper).formatNumber,
                    classes: 'form-control form-control-sm'
                  }, null, 8, ["elements", "placeholder", "normalizer"]))
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('common.filters.from')), 1),
                _createVNode(_unref(Field), {
                  name: "from",
                  type: "date",
                  modelValue: _unref(filter).from,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(filter).from) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control form-control-sm",
                      type: "date",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('drivers.placeholders.tec_exp'),
                      id: "from",
                      "aria-label": "Pec_from",
                      "aria-describedby": "from-addon"
                    }, field, {
                      fieldName: 'from',
                      autocomplete: "none"
                    }), null, 16, _hoisted_14), [
                      [_vModelText, field.value]
                    ]),
                    (errorMessage && meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('common.filters.until')), 1),
                _createVNode(_unref(Field), {
                  name: "to",
                  type: "date",
                  modelValue: _unref(filter).to,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(filter).to) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control form-control-sm",
                      type: "date",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('drivers.placeholders.tec_exp'),
                      id: "to",
                      "aria-label": "Pec_to",
                      "aria-describedby": "to-addon"
                    }, field, { autocomplete: "none" }), null, 16, _hoisted_18), [
                      [_vModelText, field.value]
                    ]),
                    (errorMessage && meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  class: "form-control-label",
                  for: "submit"
                }, null, -1)),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("button", _hoisted_23, _toDisplayString(_ctx.$t('common.actions.filter')), 1),
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-dark mt-1 me-2",
                      type: "button",
                      name: "clear",
                      onClick: clearFilters
                    }, _toDisplayString(_ctx.$t('common.actions.clear_filters')), 1)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('services.total')), 1),
                  _createElementVNode("h5", _hoisted_32, [
                    _createTextVNode(_toDisplayString(_unref(pagination).totalCount) + " ", 1),
                    _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('services.title', _unref(pagination).totalCount)), 1)
                  ])
                ])
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-3 col-sm-2 text-end" }, [
                _createElementVNode("div", { class: "icon icon-shape bg-gradient-light shadow text-center border-radius-md" }, [
                  _createElementVNode("em", { class: "fa-solid fa-arrow-up-wide-short fa-2x mt-2" })
                ])
              ], -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.$t('services.statuses.terminated')), 1),
                  _createElementVNode("h5", _hoisted_41, [
                    _createTextVNode(_toDisplayString(_unref(completed)) + " ", 1),
                    _createElementVNode("span", _hoisted_42, _toDisplayString(completedPercent.value + '%'), 1)
                  ])
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-3 col-sm-2 text-end" }, [
                _createElementVNode("div", { class: "icon icon-shape bg-gradient-primary shadow text-center border-radius-md" }, [
                  _createElementVNode("em", { class: "fa-solid text-light fa-thumbs-up fa-2x mt-2" })
                ])
              ], -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("p", _hoisted_49, _toDisplayString(_ctx.$t('services.statuses.canceled')), 1),
                  _createElementVNode("h5", _hoisted_50, [
                    _createTextVNode(_toDisplayString(_unref(canceled)) + " ", 1),
                    _createElementVNode("span", _hoisted_51, _toDisplayString(canceledPercent.value + '%'), 1)
                  ])
                ])
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-3 col-sm-2 text-end" }, [
                _createElementVNode("div", { class: "icon icon-shape bg-gradient-danger shadow text-center text-light border-radius-md" }, [
                  _createElementVNode("em", { class: "fa-solid fa-thumbs-down fa-2x mt-2" })
                ])
              ], -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(ServicesTable, {
      onShowService: show,
      table: _unref(Tables).history,
      services: _unref(history),
      pagination: _unref(pagination),
      onPaginate: paginateData
    }, null, 8, ["table", "services", "pagination"]),
    (_unref(selectedService))
      ? (_openBlock(), _createBlock(ShowServiceModal, {
          key: _unref(selectedService).id,
          service: _unref(selectedService)
        }, null, 8, ["service"]))
      : _createCommentVNode("", true)
  ]))
}
}

})