export default {
  size: 'El archivo es muy grande, maximo permitido 1024mb',
  image: 'formato de imagen no válido',
  confirm: 'Las contraseñas no son iguales',
  password: 'Permite mayúsculas, minúsculas, números, y _ - símbolos',
  driver: 'No se ha seleccionado ningún conductor',
  required: 'El campo es requerido',
  requiredNumbers: 'El Campo solo puede contener Numeros',
  NotSpaces: 'El campo no puede contener espacios',
  requiredMaxTen: 'El campo debe tener exactamente 10 caracteres',
  requiredMinTree: 'El campo debe tener al menos 3 caracteres'
}