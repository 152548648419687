import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row mx-4" }
const _hoisted_2 = { class: "col-sm-9" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_11 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_12 = { class: "col-sm-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_15 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_16 = { class: "col-sm-3" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_19 = { class: "row min-vh-75" }
const _hoisted_20 = { class: "col-sm-3 pe-4" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_23 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_24 = { class: "list-group places-group-up text-xs" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-10" }
const _hoisted_28 = { class: "col-2 text-end" }
const _hoisted_29 = ["onClick"]

import {Ref, ref, watch, onMounted} from 'vue'
import {Field, Form, FormActions} from 'vee-validate'
import * as yup from 'yup'
import PlacesRepository from '@/repositories/PlaceRepository'
import { PlaceInterface } from '@/types/PlaceInterface'
import ToastService from '@/services/ToastService'
import Place from '@/models/Place'
import i18n from '@/plugins/i18n'
import {usePlacesStore} from '@/services/stores/PlacesStore'
import Map from '@/components/maps/Map.vue'
import {storeToRefs} from 'pinia'
import {google} from 'google-maps'
import {StrHelper} from '@/helpers/StrHelper'
import {useLoadingState} from '@/services/stores/LoadingState'


export default /*@__PURE__*/_defineComponent({
  __name: 'Places',
  setup(__props) {

const place: Ref<Place> = ref(new Place)
const searchPlace: Ref<string> = ref('')
const foundPlaces: Ref<Array<Place>> = ref([])
const placesStore = usePlacesStore()
const {places} = storeToRefs(placesStore)
const selectedPlace: Ref<Array<Place>> = ref([])
const {setLoading} = useLoadingState()

onMounted(() => {
  foundPlaces.value = places.value
})

watch(searchPlace, findPlaceByName)
watch(places, (newPlaces) => {
  foundPlaces.value = newPlaces
})

const schema = yup.object().shape({
  name: yup.string().required().min(3),
  lat: yup.number().required(),
  lng: yup.number().required(),
})

function createPlace(_values: PlaceInterface, event: FormActions<any>): void {
  setLoading(true)
  place.value.name = StrHelper.toCamelCase(place.value.name)
  PlacesRepository.create(place.value).then(() => {
    setLoading(false)
    event.resetForm()
    ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.created'))
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function selectPlace(placeSelected: Place): void {
  selectedPlace.value[0] = placeSelected
}

function findPlaceByName(placeName: string): void {
  foundPlaces.value = places.value.filter(pl => {
    if (pl.name.toLowerCase().includes(placeName.toLowerCase())) {
      return pl
    }
  })
}

function onMapClick(latLng: google.maps.LatLng): void {
  place.value = new Place()
  place.value.lat = latLng.lat()
  place.value.lng = latLng.lng()
}

async function deletePlace(deletedPlace: Place): Promise<void> {
  setLoading(true)
  deletedPlace.delete().then(() => {
    setLoading(false)
    searchPlace.value = ''
    placesStore.remove(deletedPlace)
    ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.deleted'))
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Form), {
        onSubmit: createPlace,
        "validation-schema": _unref(schema),
        autocomplete: "off"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(Field), {
                  name: "name",
                  type: "text",
                  modelValue: place.value.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((place.value.name) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control",
                      id: "name",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('common.placeholders.name')
                    }, field, { autocomplete: "off" }), null, 16, _hoisted_6), [
                      [_vModelDynamic, field.value]
                    ]),
                    (errorMessage || !meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_unref(Field), {
                  name: "lat",
                  type: "text",
                  modelValue: place.value.lat,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((place.value.lat) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control",
                      id: "lat",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('services.fields.lat')
                    }, field, { autocomplete: "off" }), null, 16, _hoisted_10), [
                      [_vModelDynamic, field.value]
                    ]),
                    (errorMessage || !meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_unref(Field), {
                  name: "lng",
                  type: "text",
                  modelValue: place.value.lng,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((place.value.lng) = $event))
                }, {
                  default: _withCtx(({ field, errorMessage, meta }) => [
                    _withDirectives(_createElementVNode("input", _mergeProps({
                      class: "form-control",
                      id: "lng",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                      placeholder: _ctx.$t('services.fields.lng')
                    }, field, { autocomplete: "off" }), null, 16, _hoisted_14), [
                      [_vModelDynamic, field.value]
                    ]),
                    (errorMessage || !meta.dirty)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t('common.actions.create')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["validation-schema"]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(Map, {
          places: selectedPlace.value,
          onOnMapClick: onMapClick,
          "add-listener": true
        }, null, 8, ["places"])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('routes.places')), 1),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_unref(Field), {
          name: "lat",
          type: "search",
          modelValue: searchPlace.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchPlace).value = $event))
        }, {
          default: _withCtx(({ field, errorMessage, meta }) => [
            _withDirectives(_createElementVNode("input", _mergeProps({
              class: "form-control",
              type: "search",
              "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
              placeholder: _ctx.$t('common.placeholders.search')
            }, field, { autocomplete: "off" }), null, 16, _hoisted_22), [
              [_vModelText, field.value]
            ]),
            (errorMessage || !meta.dirty)
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(errorMessage), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("ul", _hoisted_24, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(foundPlaces.value, (place, key) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "list-group-item list-group-item-action",
            onClick: ($event: any) => (selectPlace(place)),
            key: key
          }, [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, _toDisplayString(place.name), 1),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("em", {
                  class: "fa-solid fa-trash cursor-pointer",
                  onClick: ($event: any) => (deletePlace(place))
                }, null, 8, _hoisted_29)
              ])
            ])
          ], 8, _hoisted_25))
        }), 128))
      ])
    ])
  ]))
}
}

})