import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card px-2 py-1" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = { class: "table table-sm table-borderless align-items-center mb-0" }
const _hoisted_4 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_5 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_6 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_8 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_9 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_10 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_11 = {
  key: 0,
  class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
}
const _hoisted_12 = {
  key: 1,
  class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
}
const _hoisted_13 = {
  key: 0,
  class: "text-sm text-opacity-25"
}
const _hoisted_14 = { class: "text-secondary font-weight-bolder opacity-7 text-center" }
const _hoisted_15 = { class: "py-1 col-1" }
const _hoisted_16 = { class: "py-1" }
const _hoisted_17 = { class: "py-1" }
const _hoisted_18 = { class: "py-1" }
const _hoisted_19 = { class: "py-1" }
const _hoisted_20 = ["title"]
const _hoisted_21 = {
  key: 0,
  class: "py-1"
}
const _hoisted_22 = { class: "d-flex px-2 py-0" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_25 = { class: "my-0 text-sm" }
const _hoisted_26 = { class: "text-xs text-secondary my-0" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = ["title"]
const _hoisted_29 = {
  key: 3,
  class: "py-1"
}
const _hoisted_30 = ["id"]
const _hoisted_31 = { class: "py-1 col-1" }
const _hoisted_32 = ["onClick", "title"]
const _hoisted_33 = ["title", "id"]
const _hoisted_34 = ["onClick", "title"]
const _hoisted_35 = ["onClick", "title"]
const _hoisted_36 = ["onClick", "title"]
const _hoisted_37 = {
  key: 0,
  class: "container-fluid mt-2"
}
const _hoisted_38 = {
  key: 1,
  class: "container-fluid mt-2"
}

import DateHelper from '@/helpers/DateHelper'
import Paginator from '@/components/Paginator'
import Service from '@/models/Service'
import {onBeforeUnmount, onMounted, ref, Ref, watch} from 'vue'
import {ServiceList} from '@/models/ServiceList'
import {Tables} from '@/constants/Tables'
import DBPaginator from '@/components/DBPaginator.vue'
import {Pagination} from '@/types/Pagination'
import {ServiceCursor} from '@/types/ServiceCursor'


interface Props {
  services: Array<ServiceList>
  table: Tables
  pagination: Pagination
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ServicesTable',
  props: {
    services: {},
    table: {},
    pagination: {}
  },
  emits: [
  Service.EVENT_CANCEL,
  Service.EVENT_RELEASE,
  Service.EVENT_TERMINATE,
  'paginate',
  Service.EVENT_SHOW
],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
let interval: number
const paginatedServices: Ref<Array<ServiceList>> = ref(Array<ServiceList>())

watch(props.services, (newServices) => {
  paginatedServices.value = Array.from(newServices)
})


onMounted(() => {
  paginatedServices.value = Array.from(props.services)
  if(props.table !== Tables.history) interval = window.setInterval(getTime, 1000)
})

onBeforeUnmount(() => {
  window.clearInterval(interval)
})

function format(unix: number): string {
  return DateHelper.unixToDate(unix, 'MM-DD HH:mm:ss')
}

function cancel(service: Service): void {
  emit(Service.EVENT_CANCEL, service.id)
}

function release(service: ServiceList): void {
  service.driver = null
  emit(Service.EVENT_RELEASE, service)
}

function end(service: Service): void {
  emit(Service.EVENT_TERMINATE, service.id)
}

function show(service: Service): void {
  emit(Service.EVENT_SHOW, service)
}

function getPaginatedData(data: []): void {
  paginatedServices.value = data
}

function paginatedData(page: number, next: boolean): void {
  const services = props.services;
  const cursor: ServiceCursor = {
    id: next ? services[services.length - 1].id : services[0].id,
    created: next ? services[services.length - 1].created_at : services[0].created_at,
  }
  emit('paginate', page, cursor, next)
}
function getTime(): void {
  paginatedServices.value.forEach(service => {
    service.a_go  = DateHelper.unix() - service.created_at
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("caption", { hidden: "" }, null, -1)),
        _createElementVNode("thead", null, [
          _cache[0] || (_cache[0] = _createElementVNode("th", { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 w-2" }, "#", -1)),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('services.fields.hour')), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('services.fields.status')), 1),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('services.fields.start_address')), 1),
          _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('services.fields.phone')), 1),
          _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('services.fields.name')), 1),
          _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('services.fields.comment')), 1),
          _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('services.fields.driver')), 1),
          (props.table !== _unref(Tables).pendings)
            ? (_openBlock(), _createElementBlock("th", _hoisted_11, _toDisplayString(_ctx.$t('services.fields.driver_name')), 1))
            : _createCommentVNode("", true),
          (props.table !== _unref(Tables).history)
            ? (_openBlock(), _createElementBlock("th", _hoisted_12))
            : _createCommentVNode("", true)
        ]),
        (paginatedServices.value.length > 0)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedServices.value, (service, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: service.id
                }, [
                  _createElementVNode("td", _hoisted_14, _toDisplayString(index + 1), 1),
                  _createElementVNode("td", _hoisted_15, _toDisplayString(props.table === _unref(Tables).history ? format(service.created_at) : _unref(DateHelper).aGo(service.a_go)), 1),
                  _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.$t('services.statuses.' + service.status)), 1),
                  _createElementVNode("td", _hoisted_17, _toDisplayString(service.start_loc?.name), 1),
                  _createElementVNode("td", _hoisted_18, _toDisplayString(service.phone), 1),
                  _createElementVNode("td", _hoisted_19, _toDisplayString(service.name), 1),
                  _createElementVNode("td", {
                    class: "py-1 text-truncate",
                    style: {"max-width":"100px"},
                    "data-bs-target": "tooltip",
                    title: service.comment,
                    "data-bs-placement": "top"
                  }, _toDisplayString(service.comment ?? 'N/A'), 9, _hoisted_20),
                  (service.driver)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", null, [
                            _createElementVNode("img", {
                              src: service.driver.photoUrl,
                              class: "avatar avatar-sm my-0 me-3",
                              alt: "Profile"
                            }, null, 8, _hoisted_23)
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("h6", _hoisted_25, _toDisplayString(service.driver.vehicle.plate), 1),
                            _createElementVNode("p", _hoisted_26, _toDisplayString(service.driver.phone), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_27)),
                  (service.driver)
                    ? (_openBlock(), _createElementBlock("td", {
                        key: 2,
                        class: "py-1 text-truncate",
                        style: {"max-width":"100px"},
                        "data-bs-target": "tooltip",
                        title: service.driver.name,
                        "data-bs-placement": "top"
                      }, _toDisplayString(service.driver.name), 9, _hoisted_28))
                    : (_openBlock(), _createElementBlock("td", _hoisted_29, [
                        (service.isPending())
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "btn btn-link py-1 my-0",
                              "data-bs-placement": "top",
                              "data-bs-toggle": "modal",
                              id: service.id,
                              "data-bs-target": "#driverModal"
                            }, _toDisplayString(_ctx.$t('common.actions.assign')), 9, _hoisted_30))
                          : _createCommentVNode("", true)
                      ])),
                  _createElementVNode("td", _hoisted_31, [
                    (_ctx.table != _unref(Tables).history)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-sm btn-danger btn-rounded py-1 px-2 mx-1 my-0",
                          onClick: ($event: any) => (cancel(service)),
                          "data-bs-toggle": "tooltip",
                          "data-bs-placement": "top",
                          title: _ctx.$t('common.actions.cancel')
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("em", { class: "fas fa-ban" }, null, -1)
                        ]), 8, _hoisted_32))
                      : _createCommentVNode("", true),
                    (service.isPending())
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-sm btn-secondary btn-rounded py-1 px-2 mx-1 my-0",
                          "data-bs-placement": "top",
                          title: _ctx.$t('common.actions.assign'),
                          "data-bs-toggle": "modal",
                          id: service.id,
                          "data-bs-target": "#driverModal"
                        }, _cache[2] || (_cache[2] = [
                          _createElementVNode("em", { class: "fas fa-car" }, null, -1)
                        ]), 8, _hoisted_33))
                      : _createCommentVNode("", true),
                    (service.isInProgress())
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 2,
                          class: "btn btn-sm btn-dark btn-rounded py-1 px-2 mx-1 my-0",
                          onClick: ($event: any) => (release(service)),
                          "data-bs-toggle": "tooltip",
                          "data-bs-placement": "top",
                          title: _ctx.$t('common.actions.release')
                        }, _cache[3] || (_cache[3] = [
                          _createElementVNode("em", { class: "fas fa-car-crash" }, null, -1)
                        ]), 8, _hoisted_34))
                      : _createCommentVNode("", true),
                    (service.isInProgress())
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          class: "btn btn-sm btn-dark btn-rounded py-1 px-2 mx-1 my-0",
                          onClick: ($event: any) => (end(service)),
                          "data-bs-toggle": "tooltip",
                          "data-bs-placement": "top",
                          title: _ctx.$t('common.actions.terminate')
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("em", { class: "fas fa-check" }, null, -1)
                        ]), 8, _hoisted_35))
                      : _createCommentVNode("", true),
                    (_ctx.table === _unref(Tables).history)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 4,
                          class: "btn btn-sm btn-dark btn-rounded py-1 px-2 mx-1 my-0",
                          onClick: ($event: any) => (show(service)),
                          title: _ctx.$t('common.actions.see')
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("em", { class: "fas fa-eye" }, null, -1)
                        ]), 8, _hoisted_36))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      (props.table === _unref(Tables).history)
        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
            _createVNode(DBPaginator, {
              pagination: props.pagination,
              onPaginatedData: paginatedData
            }, null, 8, ["pagination"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_38, [
            _createVNode(_unref(Paginator), {
              data: props.services,
              perPage: _ctx.pagination.perPage,
              onPaginatedData: getPaginatedData
            }, null, 8, ["data", "perPage"])
          ]))
    ])
  ]))
}
}

})