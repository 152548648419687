import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "sidenav navbar navbar-vertical bg-light navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 ps ps--active-y",
  id: "sidenav-main"
}
const _hoisted_2 = { class: "sidenav-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ms-1 font-weight-bold" }
const _hoisted_5 = {
  class: "collapse navbar-collapse w-auto max-height-vh-100 h-100 ps ps--active-y",
  id: "sidenav-collapse-main"
}
const _hoisted_6 = { class: "navbar-nav" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-link-text ms-1" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-link-text ms-1" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-link-text ms-1" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-link-text ms-1" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-link-text ms-1 position-relative" }
const _hoisted_17 = {
  key: 0,
  class: "position-absolute p-2 ms-2 bg-success border border-light rounded-circle"
}
const _hoisted_18 = {
  key: 1,
  class: "position-absolute p-2 ms-2 bg-danger border border-light rounded-circle"
}
const _hoisted_19 = { class: "nav-item" }
const _hoisted_20 = { class: "nav-link-text ms-1" }
const _hoisted_21 = { class: "nav-item" }
const _hoisted_22 = { class: "nav-link-text ms-1" }
const _hoisted_23 = { class: "nav-item" }
const _hoisted_24 = { class: "nav-link-text ms-1" }
const _hoisted_25 = { class: "position-absolute bottom-0 w-100 text-bold text-center" }

import AuthService from '@/services/AuthService'
import {version} from '../../package.json'
import User from '@/models/User'
import WhatsAppClient from "@/services/gordaApi/WhatsAppClient";
import {onMounted, onUnmounted, ref, Ref} from 'vue'
import {ClientObserver} from '@/services/gordaApi/ClientObserver'
import {useStorage} from '@/services/stores/Storage'
import {storeToRefs} from 'pinia'
import {useWpClientsStore} from "@/services/stores/WpClientStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBar',
  setup(__props) {

let user: Ref<User> = ref(new User())
let isAdmin: Ref<boolean> = ref(false)
let connectionState: Ref<boolean> = ref(false)
let socket: WhatsAppClient
let observer: ClientObserver
const storage = useStorage()
const {logoUrl} = storeToRefs(storage)
const {getDefault} = useWpClientsStore()

function signOut(): void {
  AuthService.logOut()
}

const onUpdate = (socket: WhatsAppClient): void => {
  connectionState.value = socket.isConnected()
}

onMounted(async () => {
  user.value = AuthService.getCurrentUser()
  isAdmin.value = user.value.isAdmin()
  if (getDefault()) {
    socket = WhatsAppClient.getInstance(getDefault())
    observer = new ClientObserver(onUpdate)
    socket.attach(observer)
  }
})

onUnmounted(() => {
  if (socket) socket.detach(observer)
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand m-0",
        tag: "a",
        to: {name: 'profile'}
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _unref(logoUrl),
            class: "navbar-brand-img d-inline-block align-top border-radius-lg",
            alt: "main_logo"
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(user).name), 1)
        ]),
        _: 1
      })
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "horizontal bg-dark dark mt-0" }, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("li", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: { name: 'dashboard'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/main') ? 'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("svg", {
                  id: "ico",
                  width: "12px",
                  height: "12px",
                  viewBox: "0 0 45 40",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("title", null, "shop "),
                  _createElementVNode("g", {
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd"
                  }, [
                    _createElementVNode("g", {
                      transform: "translate(-1716.000000, -439.000000)",
                      fill: "#FFFFFF",
                      "fill-rule": "nonzero"
                    }, [
                      _createElementVNode("g", { transform: "translate(1716.000000, 291.000000)" }, [
                        _createElementVNode("g", { transform: "translate(0.000000, 148.000000)" }, [
                          _createElementVNode("path", {
                            class: "color-background opacity-6",
                            d: "M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                          }),
                          _createElementVNode("path", {
                            class: "color-background",
                            d: "M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              ], -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('routes.dashboard')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_9, [
          (_unref(isAdmin))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'users'},
                tag: "a",
                class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/users/') ? 'nav-link active': 'nav-link')
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                    _createElementVNode("em", { class: "fas fa-users" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('routes.users')), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("li", _hoisted_11, [
          _createVNode(_component_router_link, {
            to: { name: 'drivers'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/drivers/') ? 'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("em", { class: "fa-solid fa-car-side" })
              ], -1)),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('routes.drivers')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_13, [
          _createVNode(_component_router_link, {
            to: { name: 'places'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/places/') ? 'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("em", { class: "fas fa-location-dot" })
              ], -1)),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('routes.places')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_15, [
          _createVNode(_component_router_link, {
            to: { name: 'wpClients'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/wp-clients/') ?
                       'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("em", { class: "fa-brands fa-whatsapp" })
              ], -1)),
              _createElementVNode("span", _hoisted_16, [
                _createTextVNode(_toDisplayString(_unref(connectionState) ? _ctx.$t('common.chatBot.connected') : _ctx.$t('common.chatBot.disconnected')) + " ", 1),
                (_unref(connectionState))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                  : (_openBlock(), _createElementBlock("span", _hoisted_18))
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_19, [
          _createVNode(_component_router_link, {
            to: { name: 'metrics'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/metrics/') ? 'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("em", { class: "fa-solid fa-chart-pie" })
              ], -1)),
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('routes.metrics')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_21, [
          _createVNode(_component_router_link, {
            to: { name: 'settings'},
            tag: "a",
            class: _normalizeClass(_ctx.$router.currentRoute.value.path.includes('/dashboard/settings/') ? 'nav-link active': 'nav-link')
          }, {
            default: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
                _createElementVNode("em", { class: "fa-solid fa-screwdriver-wrench" })
              ], -1)),
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('routes.settings')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_23, [
          _createElementVNode("a", {
            class: "nav-link",
            onClick: signOut,
            href: "",
            id: "signOut"
          }, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center" }, [
              _createElementVNode("em", { class: "fas fa-sign-out-alt" })
            ], -1)),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('users.logOut')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, _toDisplayString('version ' + _unref(version)), 1)
    ])
  ]))
}
}

})