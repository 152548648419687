import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mx-2 mt-1" }
const _hoisted_2 = {
  class: "nav nav-tabs",
  id: "myTabs",
  role: "tablist"
}
const _hoisted_3 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = {
  class: "tab-content mt-3",
  id: "myTabContent"
}
const _hoisted_10 = {
  class: "tab-pane fade",
  role: "tabpanel",
  id: "ride-fees",
  "aria-labelledby": "ride-fees-tab"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-10 col-lg-8 col-sm-12 col-xl-6 col-xxl-4 mx-auto text-center" }
const _hoisted_13 = { class: "card mx-sm-2" }
const _hoisted_14 = { class: "card-body pt-2" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-control-label" }
const _hoisted_19 = { class: "input-group" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "col-md-6" }
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = { class: "form-control-label" }
const _hoisted_25 = { class: "input-group" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = ["disabled"]
const _hoisted_28 = { class: "col-md-6" }
const _hoisted_29 = { class: "form-group" }
const _hoisted_30 = { class: "form-control-label" }
const _hoisted_31 = { class: "input-group" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = ["disabled"]
const _hoisted_34 = { class: "col-md-6" }
const _hoisted_35 = { class: "form-group" }
const _hoisted_36 = { class: "form-control-label" }
const _hoisted_37 = { class: "input-group" }
const _hoisted_38 = ["disabled"]
const _hoisted_39 = ["disabled"]
const _hoisted_40 = { class: "col-md-6" }
const _hoisted_41 = { class: "form-group" }
const _hoisted_42 = { class: "form-control-label" }
const _hoisted_43 = { class: "input-group" }
const _hoisted_44 = ["disabled"]
const _hoisted_45 = ["disabled"]
const _hoisted_46 = { class: "col-md-6" }
const _hoisted_47 = { class: "form-group" }
const _hoisted_48 = { class: "form-control-label" }
const _hoisted_49 = { class: "input-group" }
const _hoisted_50 = ["disabled"]
const _hoisted_51 = ["disabled"]
const _hoisted_52 = { class: "col-md-6" }
const _hoisted_53 = { class: "form-group" }
const _hoisted_54 = { class: "form-control-label" }
const _hoisted_55 = { class: "input-group" }
const _hoisted_56 = ["disabled"]
const _hoisted_57 = ["disabled"]
const _hoisted_58 = { class: "col-md-6" }
const _hoisted_59 = { class: "form-group" }
const _hoisted_60 = { class: "form-control-label" }
const _hoisted_61 = { class: "input-group" }
const _hoisted_62 = ["disabled"]
const _hoisted_63 = ["disabled"]
const _hoisted_64 = { class: "mt-4" }
const _hoisted_65 = ["disabled"]
const _hoisted_66 = {
  class: "tab-pane fade",
  id: "messages",
  role: "tabpanel",
  "aria-labelledby": "messages-tab"
}
const _hoisted_67 = {
  class: "tap-pane fade show active",
  role: "tabpanel",
  id: "general_settings",
  "aria-labelledby": "settings-tab"
}
const _hoisted_68 = { class: "ms-4" }
const _hoisted_69 = { class: "row" }
const _hoisted_70 = { class: "container mt-4" }
const _hoisted_71 = { class: "card" }
const _hoisted_72 = { class: "card-header" }
const _hoisted_73 = { class: "card-body" }
const _hoisted_74 = { class: "me-2" }
const _hoisted_75 = { class: "me-2" }
const _hoisted_76 = { class: "mt-3" }
const _hoisted_77 = { class: "list-group" }
const _hoisted_78 = { class: "form-check" }
const _hoisted_79 = ["for"]
const _hoisted_80 = ["onClick", "checked", "id"]

import { onMounted, ref, Ref } from 'vue'
import SettingsRepository from '@/repositories/SettingsRepository'
import { RideFeeInterface } from '@/types/RideFeeInterface'
import { useLoadingState } from '@/services/stores/LoadingState'
import SettingsMsg from '@/views/settings/messages/Index.vue'
import ToastService from '@/services/ToastService'
import i18n from '@/plugins/i18n'
import { Form } from 'vee-validate'
import {storeToRefs} from "pinia";
import {useSettingsStore} from "@/services/stores/SettingsStore";



export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsApp',
  setup(__props) {

const { setLoading } = useLoadingState()

const rideFees: Ref<RideFeeInterface> = ref({})
const { branches, branchSelected } = storeToRefs(useSettingsStore())
const { setBranchSelected } = useSettingsStore()
const fieldEdited: Ref<string> = ref('')
const submitButtonEnabled: Ref<boolean> = ref(false)
const allFieldsDisabled: Ref<boolean> = ref(true);
const currentTab: Ref<string> = ref('general_settings')

const editField = (fieldName: string) => {
  fieldEdited.value = fieldEdited.value === fieldName ? '' : fieldName
  submitButtonEnabled.value = true
  allFieldsDisabled.value = false
}

function updateAllFields(): void {
  setLoading(true)
  SettingsRepository.updateRideFee(rideFees.value).then(async () => {
    setLoading(false)
    fieldEdited.value = ''
    allFieldsDisabled.value = true
    submitButtonEnabled.value = false
    await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
    await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

onMounted(async () => {
  rideFees.value = await SettingsRepository.getRideFees()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("button", {
          class: "nav-link active",
          id: "settings-tab",
          "data-bs-toggle": "tab",
          "data-bs-target": "#general_settings",
          type: "button",
          role: "tab",
          "aria-controls": "settings",
          "aria-selected": "true",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (currentTab.value = 'general_settings'))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTab.value === 'general_settings' }])
            }, _cache[19] || (_cache[19] = [
              _createElementVNode("em", { class: "fas fa-gear" }, null, -1)
            ]), 2),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.settings.general_settings')), 1)
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("button", {
          class: "nav-link",
          id: "ride-fees-tab",
          "data-bs-toggle": "tab",
          "data-bs-target": "#ride-fees",
          type: "button",
          role: "tab",
          "aria-controls": "ride-fees",
          "aria-selected": "false",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (currentTab.value = 'rideFees'))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTab.value === 'rideFees' }])
            }, _cache[20] || (_cache[20] = [
              _createElementVNode("em", { class: "fas fa-dollar-sign" }, null, -1)
            ]), 2),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.settings.Ride_Fees')), 1)
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_7, [
        _createElementVNode("button", {
          class: "nav-link",
          id: "messages-tab",
          "data-bs-toggle": "tab",
          "data-bs-target": "#messages",
          type: "button",
          role: "tab",
          "aria-controls": "messages",
          "aria-selected": "false",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (currentTab.value = 'messages'))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTab.value === 'messages' }])
            }, _cache[21] || (_cache[21] = [
              _createElementVNode("em", { class: "fas fa-envelope" }, null, -1)
            ]), 2),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.settings.Messages')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_unref(Form), null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('common.settings.price_kilometer')), 1),
                          _createElementVNode("div", _hoisted_19, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'price_kilometer' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((rideFees.value.price_kilometer) = $event))
                            }, null, 8, _hoisted_20), [
                              [_vModelText, rideFees.value.price_kilometer]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (editField('price_kilometer'))),
                              disabled: fieldEdited.value === 'price_kilometer'
                            }, _cache[22] || (_cache[22] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_21)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('common.settings.price_minute')), 1),
                          _createElementVNode("div", _hoisted_25, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'price_minute' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((rideFees.value.price_minute) = $event))
                            }, null, 8, _hoisted_26), [
                              [_vModelText, rideFees.value.price_minute]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (editField('price_minute'))),
                              disabled: fieldEdited.value === 'price_minute'
                            }, _cache[23] || (_cache[23] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_27)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('common.settings.fees_base')), 1),
                          _createElementVNode("div", _hoisted_31, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_base' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((rideFees.value.fees_base) = $event))
                            }, null, 8, _hoisted_32), [
                              [_vModelText, rideFees.value.fees_base]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (editField('fees_base'))),
                              disabled: fieldEdited.value === 'fees_base'
                            }, _cache[24] || (_cache[24] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_33)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('common.settings.fees_additional')), 1),
                          _createElementVNode("div", _hoisted_37, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_additional' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((rideFees.value.fees_additional) = $event))
                            }, null, 8, _hoisted_38), [
                              [_vModelText, rideFees.value.fees_additional]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (editField('fees_additional'))),
                              disabled: fieldEdited.value === 'fees_additional'
                            }, _cache[25] || (_cache[25] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_39)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('common.settings.fees_minimum')), 1),
                          _createElementVNode("div", _hoisted_43, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_minimum' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((rideFees.value.fees_minimum) = $event))
                            }, null, 8, _hoisted_44), [
                              [_vModelText, rideFees.value.fees_minimum]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (editField('fees_minimum'))),
                              disabled: fieldEdited.value === 'fees_minimum'
                            }, _cache[26] || (_cache[26] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_45)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", _hoisted_47, [
                          _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('common.settings.fees_night')), 1),
                          _createElementVNode("div", _hoisted_49, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_night' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((rideFees.value.fees_night) = $event))
                            }, null, 8, _hoisted_50), [
                              [_vModelText, rideFees.value.fees_night]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[14] || (_cache[14] = ($event: any) => (editField('fees_night'))),
                              disabled: fieldEdited.value === 'fees_night'
                            }, _cache[27] || (_cache[27] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_51)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("div", _hoisted_53, [
                          _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('common.settings.fees_DxF')), 1),
                          _createElementVNode("div", _hoisted_55, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_DxF' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((rideFees.value.fees_DxF) = $event))
                            }, null, 8, _hoisted_56), [
                              [_vModelText, rideFees.value.fees_DxF]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[16] || (_cache[16] = ($event: any) => (editField('fees_DxF'))),
                              disabled: fieldEdited.value === 'fees_DxF'
                            }, _cache[28] || (_cache[28] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_57)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_58, [
                        _createElementVNode("div", _hoisted_59, [
                          _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t('common.settings.fees_night_DxF')), 1),
                          _createElementVNode("div", _hoisted_61, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-sm",
                              disabled: fieldEdited.value !== 'fees_night_DxF' || allFieldsDisabled.value,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((rideFees.value.fees_night_DxF) = $event))
                            }, null, 8, _hoisted_62), [
                              [_vModelText, rideFees.value.fees_night_DxF]
                            ]),
                            _createElementVNode("button", {
                              class: "badge bg-info border-0",
                              type: "button",
                              onClick: _cache[18] || (_cache[18] = ($event: any) => (editField('fees_night_DxF'))),
                              disabled: fieldEdited.value === 'fees_night_DxF'
                            }, _cache[29] || (_cache[29] = [
                              _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                            ]), 8, _hoisted_63)
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-primary float-end",
                        onClick: updateAllFields,
                        disabled: !submitButtonEnabled.value
                      }, _toDisplayString(_ctx.$t('common.actions.submit')), 9, _hoisted_65)
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_66, [
        (currentTab.value === 'messages')
          ? (_openBlock(), _createBlock(SettingsMsg, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_67, [
        _createElementVNode("h3", _hoisted_68, _toDisplayString(_ctx.$t('common.settings.branches')) + ":", 1),
        _createElementVNode("div", _hoisted_69, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(branches), (branch, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-6",
              key: index
            }, [
              _createElementVNode("div", _hoisted_70, [
                _createElementVNode("div", _hoisted_71, [
                  _createElementVNode("div", _hoisted_72, [
                    _createElementVNode("h2", null, _toDisplayString(branch.country), 1)
                  ]),
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", _hoisted_74, _toDisplayString(_ctx.$t('common.settings.calling_code')) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(branch.calling_code), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", _hoisted_75, _toDisplayString(_ctx.$t('common.settings.currency_code')) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(branch.currency_code), 1)
                    ]),
                    _createElementVNode("h5", _hoisted_76, _toDisplayString(_ctx.$t('common.settings.cities')), 1),
                    _createElementVNode("ul", _hoisted_77, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(branch.cities, (city) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: city.city,
                          class: "list-group-item d-flex"
                        }, [
                          _createElementVNode("div", _hoisted_78, [
                            _createElementVNode("label", {
                              class: "custom-control-label ms-2 text-sm",
                              for: city.city
                            }, _toDisplayString(city.city), 9, _hoisted_79),
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "form-check-input",
                              onClick: ($event: any) => (_unref(setBranchSelected)(branch, city)),
                              checked: _unref(branchSelected)?.city.city === city.city,
                              id: city.city
                            }, null, 8, _hoisted_80)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})