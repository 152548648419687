import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row me-2" }
const _hoisted_2 = { class: "col-sm-6 col-xxl-4 mt-2" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "chart" }
const _hoisted_7 = { class: "d-flex justify-content-center" }
const _hoisted_8 = {
  key: 0,
  class: "fa-solid fa-spinner fa-10x circle"
}
const _hoisted_9 = { class: "col-sm-6 col-xxl-4 mt-2" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card-header" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "chart" }
const _hoisted_14 = { class: "d-flex justify-content-center" }
const _hoisted_15 = {
  key: 0,
  class: "fa-solid fa-spinner fa-10x circle"
}
const _hoisted_16 = { class: "col-sm-6 col-xxl-4 mt-2" }
const _hoisted_17 = { class: "chart" }
const _hoisted_18 = { class: "card" }
const _hoisted_19 = { class: "card-header d-flex" }
const _hoisted_20 = ["value"]
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "chart" }
const _hoisted_24 = { class: "d-flex justify-content-center" }
const _hoisted_25 = {
  key: 0,
  class: "fa-solid fa-spinner fa-10x circle"
}

import {Bar, Line} from 'vue-chartjs'
import {onBeforeMount, ref, Ref, watch} from 'vue'
import {useMetricsStore} from '@/services/stores/MetricsStore'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement, ChartOptions, ChartData,
} from 'chart.js'
import {useI18n} from 'vue-i18n'
import { TopFrequency } from '@/constants/TopFrequency'


export default /*@__PURE__*/_defineComponent({
  __name: 'Metrics',
  setup(__props) {

Chart.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, BarElement)
const {getCurrentYearMetric, globalYearMetric, canceledYearMetric, completedYearMetric, percentYearMetric, top5DailyMetric, getTop5Metric, loaded, loading} = useMetricsStore()
const {t} = useI18n()
const percentChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      ticks: {
        callback: function(value) {
          return value + '%';
        }
      }
    }
  }
}
const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true
}

const dailyTop5chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  indexAxis: 'y'
}
const isLoaded: Ref<boolean> = ref(false)
const isTopLoaded: Ref<boolean> = ref(false)
const frequency = ref<TopFrequency>(TopFrequency.Daily)

let globalChartData: ChartData
let percentChartData: ChartData
let dailyTop5ChartData: ChartData

watch(frequency, async (newFrequency) => {
  isTopLoaded.value = false
  await getTop5Metric(frequency.value)
  setTop5Metric()
  isTopLoaded.value = true
})

onBeforeMount(async () => {
  await getTop5Metric(frequency.value)
  if (!loaded && !loading) await getCurrentYearMetric()
  isLoaded.value = true
  isTopLoaded.value = true
  globalChartData = {
    labels: Array.from(globalYearMetric.keys()),
    datasets: [
      {
        label: t('services.total'),
        data: Array.from(globalYearMetric.values()),
        backgroundColor: '#ffd500'
      },
      {
        label: t('services.statuses.terminated'),
        data: Array.from(completedYearMetric.values()),
        backgroundColor: '#00ff05'
      },
      {
        label: t('services.statuses.canceled'),
        data: Array.from(canceledYearMetric.values()),
        backgroundColor: '#ff0000'
      }
    ]
  }
  percentChartData = {
    labels: Array.from(globalYearMetric.keys()),
    datasets: [
      {
        label: t('common.placeholders.cancel_percent'),
        data: Array.from(percentYearMetric.values()),
        backgroundColor: '#e81022'
      }
    ]
  }

  setTop5Metric()
})

function setTop5Metric(): void {
  dailyTop5ChartData = {
    labels: Array.from(top5DailyMetric.keys()),
    datasets: [{
      indexAxis: 'y',
      label: t('common.placeholders.first_place'),
      data: Array.from(top5DailyMetric.values()),
      fill: false,
      backgroundColor: [
        '#ff0000',
        '#00ff',
        '#d505',
        '#cc0005',
        '#ffd500'
      ]
    }]
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", null, _toDisplayString(_unref(t)('common.placeholders.year_service_progress')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (isLoaded.value)
              ? (_openBlock(), _createBlock(_unref(Line), {
                  key: 0,
                  id: "global-chart",
                  options: chartOptions,
                  data: _unref(globalChartData)
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (!isLoaded.value)
              ? (_openBlock(), _createElementBlock("em", _hoisted_8))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h4", null, _toDisplayString(_unref(t)('common.placeholders.cancel_percent')), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (isLoaded.value)
              ? (_openBlock(), _createBlock(_unref(Bar), {
                  key: 0,
                  id: "percent-chart",
                  options: percentChartOptions,
                  data: _unref(percentChartData)
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            (!isLoaded.value)
              ? (_openBlock(), _createElementBlock("em", _hoisted_15))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("h4", null, _toDisplayString(frequency.value == _unref(TopFrequency).Daily ? _unref(t)('common.placeholders.daily_top_5') : _unref(t)('common.placeholders.weekly_top_5')), 1),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((frequency).value = $event)),
              class: "form-control form-control-sm ms-auto w-25"
            }, [
              _createElementVNode("option", {
                value: _unref(TopFrequency).Daily
              }, _toDisplayString(_unref(t)('common.placeholders.daily')), 9, _hoisted_20),
              _createElementVNode("option", {
                value: _unref(TopFrequency).Weekly
              }, _toDisplayString(_unref(t)('common.placeholders.weekly')), 9, _hoisted_21)
            ], 512), [
              [_vModelSelect, frequency.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              (isTopLoaded.value)
                ? (_openBlock(), _createBlock(_unref(Bar), {
                    key: 0,
                    id: "daily-top-5-chart",
                    options: dailyTop5chartOptions,
                    data: _unref(dailyTop5ChartData)
                  }, null, 8, ["data"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_24, [
              (!isTopLoaded.value)
                ? (_openBlock(), _createElementBlock("em", _hoisted_25))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})