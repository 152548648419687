import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, vModelText as _vModelText, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  class: "nav nav-tabs",
  id: "myTab",
  role: "tablist"
}
const _hoisted_3 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "d-none d-sm-inline" }
const _hoisted_6 = { class: "p-1" }
const _hoisted_7 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "d-none d-sm-inline" }
const _hoisted_10 = { class: "p-1" }
const _hoisted_11 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "d-none d-sm-inline" }
const _hoisted_14 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = { class: "d-none d-sm-inline" }
const _hoisted_17 = {
  class: "tab-content pt-2",
  id: "myTabContent"
}
const _hoisted_18 = {
  class: "tab-pane fade show active",
  id: "pending",
  role: "tabpanel",
  "aria-labelledby": "pending-tab"
}
const _hoisted_19 = {
  class: "tab-pane fade",
  id: "progress",
  role: "tabpanel",
  "aria-labelledby": "progress-tab"
}
const _hoisted_20 = { class: "form-group me-2 col-sm-4" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = {
  class: "tab-pane fade",
  id: "history",
  role: "tabpanel",
  "aria-labelledby": "history-tab"
}
const _hoisted_23 = {
  class: "tab-pane fade card card-body",
  id: "mapTab",
  role: "tabpanel",
  "aria-labelledby": "map-tab"
}

import CreateService from '@/components/services/CreateService.vue'
import ServicesTable from '@/components/services/ServicesTable.vue'
import ServiceRepository from '@/repositories/ServiceRepository'
import Service from '@/models/Service'
import ToastService from '@/services/ToastService'
import AssignDriver from '@/components/services/AssingDriver.vue'
import {onMounted, reactive, ref, Ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {storeToRefs} from 'pinia'
import {useDriversStore} from '@/services/stores/DriversStore'
import DriverMap from '@/components/DriverMap.vue'
import History from '@/components/services/History.vue'
import {useServicesStore} from '@/services/stores/ServiceStore'
import {Tables} from '@/constants/Tables'
import {ServiceList} from '@/models/ServiceList'
import {Pagination} from "@/types/Pagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'Tabs',
  setup(__props) {

const {t} = useI18n()
const driverStore = useDriversStore()
const {pendings, inProgress} = storeToRefs(useServicesStore())
const {filterInProgressServices} = useServicesStore()
const {drivers} = storeToRefs(driverStore)
const currentTap: Ref<string> = ref('pendings')
const searchService: Ref<string> = ref('')
const filteredInProgress: Ref<Array<ServiceList>> = ref([])
const paginationInProgress = reactive<Pagination>({
  totalCount: 0,
  currentPage: 1,
  perPage: 20
})
const paginationPendings = reactive<Pagination>({
  totalCount: 0,
  currentPage: 1,
  perPage: 20
})

watch(pendings, (newPendings) => {
  paginationPendings.totalCount = newPendings.length
})
watch(searchService, (search) => {
  filteredInProgress.value.splice(0, filteredInProgress.value.length)
  if (search.length > 2) {
    filterInProgressServices(search).forEach(service => {
      filteredInProgress.value.push(service)
      paginationInProgress.totalCount = filteredInProgress.value.length
    })
  } else {
    inProgress.value.forEach(service => {
      filteredInProgress.value.push(service)
      paginationInProgress.totalCount = filteredInProgress.value.length
    })
  }
})

watch(inProgress.value, (services) => {
  if (searchService.value.length <= 2) {
    filteredInProgress.value.splice(0, filteredInProgress.value.length)
    services.forEach(service => {
      filteredInProgress.value.push(service)
      paginationInProgress.totalCount = filteredInProgress.value.length
    })
  }
})

function cancel(serviceId: string): void {
  ServiceRepository.updateStatus(serviceId, Service.STATUS_CANCELED).then(async () => {
    await ToastService.toast(ToastService.SUCCESS, t('common.messages.updated'))
  }).catch(async (e) => {
    await ToastService.toast(ToastService.ERROR, t('common.messages.error'), e.message)
  })
}

function release(service: Service): void {
  if (!service.driver_id) return
  ServiceRepository.release(service.id).then(async () => {
    await ToastService.toast(ToastService.SUCCESS, t('common.messages.updated'))
  }).catch(async (e) => {
    await ToastService.toast(ToastService.ERROR, t('common.messages.error'), e.message)
  })
}

function end(serviceId: string): void {
  ServiceRepository.updateStatus(serviceId, Service.STATUS_TERMINATED).then(async () => {
    await ToastService.toast(ToastService.SUCCESS, t('common.messages.updated'))
  }).catch(async (e) => {
    await ToastService.toast(ToastService.ERROR, t('common.messages.error'), e.message)
  })
}

onMounted(() => {
  filteredInProgress.value.splice(0, filteredInProgress.value.length)
  inProgress.value.forEach(service => {
    filteredInProgress.value.push(service)
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("a", {
          class: "nav-link active",
          id: "pending-tab",
          "data-bs-toggle": "tab",
          "data-bs-target": "#pending",
          type: "button",
          role: "tab",
          "aria-controls": "pending",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (currentTap.value = 'pending')),
          "aria-selected": "true"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTap.value === 'pending' }])
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("em", { class: "fa-regular fa-clock" }, null, -1)
            ]), 2),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('services.statuses.pending')), 1),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("span", { class: "badge badge-circle bg-danger" }, _toDisplayString(_unref(pendings).length), 513), [
                [_vShow, _unref(pendings).length > 0]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_7, [
        _createElementVNode("a", {
          class: "nav-link",
          id: "progress-tab",
          "data-bs-toggle": "tab",
          "data-bs-target": "#progress",
          type: "button",
          role: "tab",
          "aria-controls": "progress",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (currentTap.value = 'progress')),
          "aria-selected": "false"
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTap.value === 'progress' }])
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("em", { class: "fa-solid fa-spinner" }, null, -1)
            ]), 2),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('services.statuses.in_progress')), 1),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("span", { class: "badge badge-circle bg-success" }, _toDisplayString(_unref(inProgress).length), 513), [
                [_vShow, _unref(inProgress).length > 0]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("a", {
          class: "nav-link",
          id: "history-tab",
          "data-bs-toggle": "tab",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (currentTap.value = 'history')),
          "data-bs-target": "#history",
          type: "button",
          role: "tab",
          "aria-controls": "history",
          "aria-selected": "false"
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTap.value === 'history' }])
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("em", { class: "fa-regular fa-folder-open" }, null, -1)
            ]), 2),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('services.history')), 1)
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_14, [
        _createElementVNode("a", {
          class: "nav-link",
          id: "map-tab",
          "data-bs-toggle": "tab",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (currentTap.value = 'mapTab')),
          "data-bs-target": "#mapTab",
          type: "button",
          role: "tab",
          "aria-controls": "map",
          "aria-selected": "false"
        }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon icon-shape icon-sm border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center", { 'shadow': currentTap.value === 'mapTab' }])
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("em", { class: "fa-solid fa-map-location-dot" }, null, -1)
            ]), 2),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('common.placeholders.map')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(CreateService),
        _createVNode(ServicesTable, {
          table: _unref(Tables).pendings,
          services: _unref(pendings),
          onCancelService: cancel,
          pagination: paginationPendings
        }, null, 8, ["table", "services", "pagination"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _withDirectives(_createElementVNode("input", {
            class: "form-control form-control-sm me-2",
            type: "search",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((searchService).value = $event)),
            name: "search",
            placeholder: _ctx.$t('common.placeholders.search'),
            autocomplete: "off"
          }, null, 8, _hoisted_21), [
            [_vModelText, searchService.value]
          ])
        ]),
        _createVNode(ServicesTable, {
          table: _unref(Tables).inProgress,
          services: filteredInProgress.value,
          onCancelService: cancel,
          onEndService: end,
          onReleaseService: release,
          pagination: paginationInProgress
        }, null, 8, ["table", "services", "pagination"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        (currentTap.value === 'history')
          ? (_openBlock(), _createBlock(History, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_23, [
        (currentTap.value === 'mapTab')
          ? (_openBlock(), _createBlock(DriverMap, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(AssignDriver, { drivers: _unref(drivers) }, null, 8, ["drivers"])
  ]))
}
}

})