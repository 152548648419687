import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "form-label" }
const _hoisted_9 = { class: "modal-footer" }
const _hoisted_10 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_11 = {
  type: "submit",
  class: "btn bg-gradient-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.id,
    tabindex: "-1",
    role: "dialog",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('users.forms.upload')), 1),
          _cache[1] || (_cache[1] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, [
            _createElementVNode("span", { "aria-hidden": "true" }, "×")
          ], -1))
        ]),
        _createVNode(_component_Form, {
          onSubmit: _ctx.uploadImg,
          "validation-schema": _ctx.schemaImg
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('users.forms.select_img')), 1),
                _createVNode(_component_Field, {
                  name: "image",
                  class: "form-control",
                  type: "file",
                  accept: "image/*",
                  modelValue: _ctx.image,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.image) = $event)),
                  multiple: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "image",
                  class: "is-invalid"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t('common.actions.close')), 1),
              _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])
    ])
  ], 8, _hoisted_1))
}