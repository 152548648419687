import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid d-flex align-items-center row" }
const _hoisted_2 = { class: "col-6 col-sm-4 col-md-6 col-lg-4 form-group d-inline-flex align-items-end" }
const _hoisted_3 = { class: "me-2 text-nowrap d-md-block d-none" }
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["selected"]
const _hoisted_6 = ["selected"]
const _hoisted_7 = {
  "aria-label": "...",
  class: "col-6 col-sm-8 col-md-6 col-lg-4"
}
const _hoisted_8 = { class: "pagination" }
const _hoisted_9 = { class: "page-link" }

import {ref, computed, watch, Ref} from 'vue'
import {Pagination} from "@/types/Pagination";
import {storeToRefs} from 'pinia'
import {useServicesStore} from '@/services/stores/ServiceStore'

interface Props {
  pagination: Pagination
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DBPaginator',
  props: {
    pagination: {}
  },
  emits: ['paginatedData'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const totalPages = computed(() => Math.ceil(props.pagination.totalCount / props.pagination.perPage))
const {pagination: storePagination} = storeToRefs(useServicesStore())
const currentPage: Ref<number> = ref(storePagination.value.currentPage)

watch(() => props.pagination.currentPage, (page) => {
  currentPage.value = page
})

function nextPage(): void {
  if (currentPage.value < totalPages.value) {
    currentPage.value ++
    emitPageData(currentPage.value, true)
  }
}

function backPage(): void {
  if (currentPage.value > 1) {
    currentPage.value--
    emitPageData(currentPage.value, false)
  }
}

function emitPageData(page: number, next: boolean): void {
  emit('paginatedData', page, next)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('common.actions.items_pages')), 1),
      _withDirectives(_createElementVNode("select", {
        class: "form-select form-select-sm",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(storePagination).perPage) = $event))
      }, [
        _createElementVNode("option", {
          value: 20,
          selected: currentPage.value === 20
        }, "20", 8, _hoisted_4),
        _createElementVNode("option", {
          value: 30,
          selected: currentPage.value === 30
        }, "30", 8, _hoisted_5),
        _createElementVNode("option", {
          value: 50,
          selected: currentPage.value === 50
        }, "50", 8, _hoisted_6)
      ], 512), [
        [_vModelSelect, _unref(storePagination).perPage]
      ])
    ]),
    _createElementVNode("nav", _hoisted_7, [
      _createElementVNode("ul", _hoisted_8, [
        _createElementVNode("li", { class: "page-item" }, [
          _createElementVNode("a", {
            class: "page-link cursor-pointer",
            onClick: backPage,
            tabindex: "-1"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("em", { class: "fa fa-angle-left" }, null, -1),
            _createElementVNode("span", { class: "sr-only" }, "Previous", -1)
          ]))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(totalPages.value, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (item === 1 || item === totalPages.value || (item >= currentPage.value - 1 && item <= currentPage.value + 1))
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass(["page-item cursor-pointer", { active: currentPage.value === item }])
                }, [
                  _createElementVNode("span", _hoisted_9, [
                    _createTextVNode(_toDisplayString(item) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "(current)", -1))
                  ])
                ], 2))
              : (item === totalPages.value - 1 || item === 2)
                ? (_openBlock(), _createElementBlock("li", {
                    key: `dots-${index}`,
                    class: "page-item disabled"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("span", { class: "page-link" }, "...", -1)
                  ])))
                : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createElementVNode("li", { class: "page-item cursor-pointer" }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: nextPage
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("em", { class: "fa fa-angle-right" }, null, -1),
            _createElementVNode("span", { class: "sr-only" }, "Next", -1)
          ]))
        ])
      ])
    ])
  ]))
}
}

})