import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Tabs from '@/components/services/Tabs.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Main',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(Tabs)
  ]))
}
}

})