import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body row" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = {
  for: "message-name",
  class: "col-form-label"
}
const _hoisted_9 = {
  for: "message-text",
  class: "col-form-label"
}
const _hoisted_10 = { class: "emoji-picker position-absolute" }
const _hoisted_11 = { class: "d-flex align-items-center flex-wrap" }
const _hoisted_12 = ["title", "onClick"]
const _hoisted_13 = { class: "col-md-6" }
const _hoisted_14 = {
  for: "message-text",
  class: "col-form-label"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_17 = {
  for: "description-text",
  class: "col-form-label"
}
const _hoisted_18 = { class: "modal-footer" }
const _hoisted_19 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}

import {ref, computed, Ref, onMounted} from 'vue'
import SettingsRepository from '@/repositories/SettingsRepository'
import ToastService from '@/services/ToastService'
import { useLoadingState } from '@/services/stores/LoadingState'
import { SettingsMessageInterface } from '@/types/SettingsMessagesInterface'
import { hide } from '@/helpers/ModalHelper'
import i18n from '@/plugins/i18n'
import EmojiPicker, {EmojiExt} from 'vue3-emoji-picker'


export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  props: {
    selectedMessage: {}
  },
  emits: ['updateMessages'],
  setup(__props: any, { emit: __emit }) {

const { setLoading } = useLoadingState()
const textArea = ref<HTMLTextAreaElement | null>(null)
const isEmojiPickerOpen = ref(false)
const emit = __emit
const props = __props
const newMessage: Ref<string> = ref('')

const placeholders = [
  { description: 'common.placeholders.description.plate', label: 'common.placeholders.label.plate', value: '[[PLATE]]' },
  { description: 'common.placeholders.description.vehicle_color', label: 'common.placeholders.label.color', value: '[[COLOR]]' },
  { description: 'common.placeholders.description.username', label: 'common.placeholders.label.name', value: '[[USERNAME]]' },
  { description: 'common.placeholders.description.company_number', label: 'common.placeholders.label.number_pqr', value: '[[PQR-NUMBER]]' },
  { description: 'common.placeholders.description.place_name', label: 'common.placeholders.label.place_name', value: '[[PLACE]]' },
  { description: 'common.placeholders.description.company_name', label: 'common.placeholders.label.company_name', value: '[[COMPANY]]' },
]

onMounted(async() => {
  newMessage.value = props.selectedMessage.message?? ''
})

function updateTextareaMessage() {
  const textarea = textArea.value
  if (textarea) {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }
}

function insertPlaceholder(placeholder: string): void {
  if (placeholder && textArea.value) {
    const startPos = textArea.value.selectionStart ?? 0
    const endPos = textArea.value.selectionEnd ?? 0
    const scrollTop = textArea.value.scrollTop
    const value = textArea.value.value
    textArea.value.value = value.substring(0, startPos) + placeholder + value.substring(endPos, value.length)
    textArea.value.focus()
    textArea.value.setSelectionRange(startPos + placeholder.length, startPos + placeholder.length)
    textArea.value.scrollTop = scrollTop
  }
}

function applyStyleToSelection(style: string, text?: string): void {
  if (!textArea.value) return
  const startPos = textArea.value.selectionStart ?? 0
  const endPos = textArea.value.selectionEnd ?? 0
  const selectedText = text || (textArea.value.value.substring(startPos, endPos))
  const styledText = style === 'b' ? `*${selectedText}*` : `_${selectedText}_`
  const value = textArea.value.value
  textArea.value.value = value.substring(0, startPos) + styledText + value.substring(endPos, value.length)
  textArea.value.focus()
  const newEndPos = startPos + styledText.length - 1
  textArea.value.setSelectionRange(newEndPos, newEndPos)
}

function applyStyle(style: string): void {
  if (style === 'b' || style === 'i') {
    applyStyleToSelection(style)
  }
}

function insertTextAtCursor(text: string): void {
  newMessage.value += text
  if (textArea.value) {
    textArea.value.focus()
  }
}

function letterBold(): void {
  applyStyle('b')
}

function letterItalic(): void {
  applyStyle('i')
}

function insertEmoji(emoji: EmojiExt): void {
  if (emoji.i) {
    insertTextAtCursor(emoji.i)
    toggleEmojiPicker()
  }
}

function toggleEmojiPicker(): void {
  isEmojiPickerOpen.value = !isEmojiPickerOpen.value;
}

function saveChanges(): void {
  if (props.selectedMessage && textArea.value) {
    setLoading(true)
    const updatedMessage = {
      id: props.selectedMessage.id,
      name: props.selectedMessage.name,
      description: props.selectedMessage.description,
      message: textArea.value.value,
      enabled: props.selectedMessage.enabled 
    }
    SettingsRepository.updateMessage(updatedMessage).then(async () => {
      emit('updateMessages')
      setLoading(false)
      hide(updatedMessage.id)
      await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
    }).catch(async e => {
      setLoading(false)
      await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
    })
  }
}

const formattedMessage = computed(() => {
  let content = newMessage.value
  const tabs = content.match(/\t/g) || []
  const newLines = content.match(/\n/g) || []

  content = content
  .replace(/\*([^*]+)\*/g, '<b>$1</b>')
  .replace(/_([^_]+)_/g, '<i>$1</i>')

  for (let i = 0; i < tabs.length; i++) {
    content = content.replace(/\t/, '&#9;')
  }

  for (let i = 0; i < newLines.length; i++) {
    content = content.replace(/\n/, '<br>')
  }

  return content;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.selectedMessage.id,
    class: "modal fade",
    tabindex: "-1",
    "aria-labelledby": "exampleModalLabel",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('common.titles.title_modal')), 1),
          _cache[3] || (_cache[3] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('common.fields.name')), 1),
            _withDirectives(_createElementVNode("input", {
              ref_key: "textArea",
              ref: textArea,
              name: "message-name",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$props.selectedMessage.name) = $event))
            }, null, 512), [
              [_vModelText, _ctx.$props.selectedMessage.name]
            ]),
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('common.fields.label_message')), 1),
            _withDirectives(_createElementVNode("textarea", {
              rows: "5",
              ref_key: "textArea",
              ref: textArea,
              id: "editorText",
              class: "form-control text-area-Message",
              contenteditable: "true",
              onInput: updateTextareaMessage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newMessage).value = $event))
            }, null, 544), [
              [_vModelText, newMessage.value]
            ]),
            _createElementVNode("div", { class: "d-flex mt-1" }, [
              _createElementVNode("button", {
                class: "bold-button btn btn-sm btn-info btn-squared px-4 py-2 active",
                onClick: letterBold
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("b", null, "B", -1)
              ])),
              _createElementVNode("button", {
                class: "italic-button btn btn-sm btn-info btn-squared px-4 py-2 active ms-1",
                onClick: letterItalic
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("span", { class: "fs-6" }, "I", -1),
                _createElementVNode("i", null, null, -1)
              ])),
              _createElementVNode("button", {
                class: "emoji-button btn btn-sm btn-info btn-squared px-4 py-2 ms-1",
                onClick: toggleEmojiPicker
              }, " 😀 ")
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(EmojiPicker), { onSelect: insertEmoji })
            ], 512), [
              [_vShow, isEmojiPickerOpen.value]
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(placeholders, (placeholder, index) => {
                return _createElementVNode("div", { key: index }, [
                  _createElementVNode("span", {
                    class: "tooltip-element badge bg-secondary ms-1",
                    "data-bs-toggle": "tooltip",
                    title: _ctx.$t(placeholder.description),
                    onClick: ($event: any) => (insertPlaceholder(placeholder.value))
                  }, _toDisplayString(_ctx.$t(placeholder.label)), 9, _hoisted_12)
                ])
              }), 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('common.fields.label_preview')), 1),
            _createElementVNode("div", {
              class: "preview-container form-control",
              innerHTML: formattedMessage.value,
              disabled: ""
            }, null, 8, _hoisted_15),
            (_ctx.$props.selectedMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('common.fields.label_description')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    class: "form-control text-area-Description",
                    id: "description-text",
                    "aria-label": "Description",
                    "aria-describedby": "description-addon",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$props.selectedMessage.description) = $event)),
                    rows: "5",
                    onInput: updateTextareaMessage
                  }, null, 544), [
                    [_vModelText, _ctx.$props.selectedMessage.description]
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("button", _hoisted_19, _toDisplayString(_ctx.$t('common.actions.close')), 1),
          _createElementVNode("button", {
            type: "submit",
            class: "btn bg-gradient-primary",
            onClick: saveChanges
          }, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}
}

})