import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mx-3" }
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "row m-3" }
const _hoisted_4 = {
  key: 0,
  class: "col-4"
}
const _hoisted_5 = { class: "ms-2" }
const _hoisted_6 = { class: "form-group d-inline-flex" }
const _hoisted_7 = { class: "me-2" }
const _hoisted_8 = { value: -1 }
const _hoisted_9 = { value: 1 }
const _hoisted_10 = { value: 0 }
const _hoisted_11 = { class: "form-group me-2 w-100 col" }
const _hoisted_12 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_14 = { class: "card-body px-0 pt-0 pb-2" }
const _hoisted_15 = { class: "table-responsive p-0" }
const _hoisted_16 = { class: "table align-items-center mb-0" }
const _hoisted_17 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_18 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_19 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_20 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_21 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_22 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_23 = { class: "py-0" }
const _hoisted_24 = { class: "d-flex px-2 py-1" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_27 = { class: "mb-0 text-sm" }
const _hoisted_28 = { class: "text-xs text-secondary mb-0" }
const _hoisted_29 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_30 = { class: "py-0" }
const _hoisted_31 = { class: "d-flex px-2 py-1" }
const _hoisted_32 = ["src"]
const _hoisted_33 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_34 = { class: "mb-0 text-sm" }
const _hoisted_35 = { class: "text-xs text-secondary mb-0" }
const _hoisted_36 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_37 = { class: "align-middle text-center text-sm" }
const _hoisted_38 = { class: "align-middle text-center" }
const _hoisted_39 = { class: "text-secondary text-xs font-weight-bold" }
const _hoisted_40 = { class: "align-middle p-0" }
const _hoisted_41 = { class: "row row-cols-2 mx-2" }
const _hoisted_42 = {
  key: 0,
  class: "form-check form-switch col-2"
}
const _hoisted_43 = ["checked", "id"]
const _hoisted_44 = { class: "col-4" }
const _hoisted_45 = { class: "container text-center mt-2" }

import DateHelper from '@/helpers/DateHelper'
import Paginator from '@/components/Paginator.vue'
import {useDriversStore} from '@/services/stores/DriversStore'
import {Field} from 'vee-validate'
import Driver from '@/models/Driver'
import {onMounted, ref, Ref, watchEffect} from 'vue'
import dayjs from 'dayjs'
import DriverRepository from '@/repositories/DriverRepository'
import i18n from '@/plugins/i18n'
import ToastService from '@/services/ToastService'
import {useLoadingState} from '@/services/stores/LoadingState'
import AuthService from '@/services/AuthService'


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const {drivers, filter, findById} = useDriversStore()
const paginatedDrivers: Ref<Array<Driver>> = ref([])
const filteredDrivers: Ref<Array<Driver>> = ref([])
const searchDriver: Ref<string> = ref('')
const {setLoading} = useLoadingState()
const enabled: Ref<number> = ref(-1)
const currentUser = AuthService.getCurrentUser()

function format(unix: number): string {
  return DateHelper.unixToDate(unix, 'YYYY-MM-DD')
}

function getPaginatedData(data: []): void {
  paginatedDrivers.value = data
}

watchEffect(() => {
  const filtered = filter(searchDriver.value, enabled.value);
  filteredDrivers.value.splice(0, filteredDrivers.value.length, ...filtered)
})

watchEffect(() => {
  const filtered = filter(searchDriver.value, enabled.value);
  filteredDrivers.value.splice(0, filteredDrivers.value.length, ...filtered)
})

watchEffect(() => {
  const filtered = filter(searchDriver.value, enabled.value);
  filteredDrivers.value.splice(0, filteredDrivers.value.length, ...filtered)
})

onMounted(() => {
  filteredDrivers.value.splice(0, filteredDrivers.value.length, ...(drivers))
})

function onEnable(event: Event): void {
  setLoading(true)
  const target = event.target as HTMLInputElement
  const driver = findById(target.id) ?? new Driver()
  driver.enabled_at = target.checked ? dayjs().unix() : 0
  DriverRepository.enable(driver.id, driver.enabled_at).then(() => {
    setLoading(false)
    const message = driver.enabled_at == 0 ?
        i18n.global.t('users.messages.disabled') : i18n.global.t('users.messages.enabled')
    ToastService.toast(ToastService.SUCCESS, message)
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(currentUser) && _unref(currentUser).isAdmin())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.$t('common.models.drivers', filteredDrivers.value.length) + ' ' + filteredDrivers.value.length), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["col-4 d-flex justify-content-end", { 'col-8': !(_unref(currentUser) && _unref(currentUser).isAdmin()) }])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('common.actions.see')), 1),
            _withDirectives(_createElementVNode("select", {
              class: "form-select form-select-sm",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((enabled).value = $event))
            }, [
              _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$t('common.placeholders.all')), 1),
              _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$t('common.fields.enabled')), 1),
              _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t('common.fields.disabled')), 1)
            ], 512), [
              [_vModelSelect, enabled.value]
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col-4 d-flex justify-content-end", { 'col-3': !(_unref(currentUser) && _unref(currentUser).isAdmin()) }])
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(Field), {
              name: "driver",
              type: "search",
              modelValue: searchDriver.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchDriver).value = $event))
            }, {
              default: _withCtx(({ field, errorMessage, meta }) => [
                _withDirectives(_createElementVNode("input", _mergeProps({
                  class: "form-control form-control-sm me-2",
                  type: "search",
                  "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                  placeholder: _ctx.$t('common.placeholders.search')
                }, field, { autocomplete: "off" }), null, 16, _hoisted_12), [
                  [_vModelText, field.value]
                ]),
                (errorMessage || !meta.dirty)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(errorMessage), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createVNode(_component_router_link, {
            to: { name: 'drivers.create'},
            tag: "a",
            class: "btn btn-sm btn-primary btn-rounded float-end",
            "data-original-title": "Create Driver"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("em", { class: "fas fa-plus" }, null, -1)
            ])),
            _: 1
          })
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("table", _hoisted_16, [
            _cache[4] || (_cache[4] = _createElementVNode("caption", { hidden: "" }, null, -1)),
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t('common.fields.name')), 1),
                _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t('common.fields.phone')), 1),
                _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t('drivers.fields.vehicle')), 1),
                _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t('drivers.fields.plate')), 1),
                _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t('common.fields.status')), 1),
                _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t('common.fields.createdAt')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedDrivers.value, (driver) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: driver.id
                }, [
                  _createElementVNode("td", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: driver.photoUrl,
                          class: "avatar avatar-sm me-3",
                          alt: "Profile image"
                        }, null, 8, _hoisted_25)
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("h6", _hoisted_27, _toDisplayString(driver.name), 1),
                        _createElementVNode("p", _hoisted_28, _toDisplayString(driver.email), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_29, _toDisplayString(driver.phone), 1)
                  ]),
                  _createElementVNode("td", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: driver.vehicle.photoUrl,
                          class: "avatar avatar-sm me-3",
                          alt: "Profile image"
                        }, null, 8, _hoisted_32)
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("h6", _hoisted_34, _toDisplayString(driver.vehicle.brand), 1),
                        _createElementVNode("p", _hoisted_35, _toDisplayString(driver.vehicle.model), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_36, _toDisplayString(driver.vehicle.plate), 1)
                  ]),
                  _createElementVNode("td", _hoisted_37, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["badge badge-sm", driver.enabled_at ? 'bg-gradient-success' : 'bg-gradient-danger'])
                    }, _toDisplayString(_ctx.$t(driver.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 3)
                  ]),
                  _createElementVNode("td", _hoisted_38, [
                    _createElementVNode("span", _hoisted_39, _toDisplayString(format(driver.created_at)), 1)
                  ]),
                  _createElementVNode("td", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      (_unref(currentUser) && _unref(currentUser).isAdmin())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                            _createElementVNode("input", {
                              class: "form-check-input",
                              name: "enable",
                              type: "checkbox",
                              checked: driver.isEnabled(),
                              id: driver.id,
                              onChange: onEnable
                            }, null, 40, _hoisted_43)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_44, [
                        _createVNode(_component_router_link, {
                          to: { name: 'drivers.edit', params: { id: driver.id } },
                          tag: "a",
                          class: "btn btn-sm btn-info btn-rounded rounded-pill py-1 m-0",
                          "data-original-title": "Edit Driver"
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                          ])),
                          _: 2
                        }, 1032, ["to"])
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_45, [
            _createVNode(Paginator, {
              data: filteredDrivers.value,
              perPage: 30,
              onPaginatedData: getPaginatedData
            }, null, 8, ["data"])
          ])
        ])
      ])
    ])
  ]))
}
}

})