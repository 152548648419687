import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { "aria-label": "..." }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = ["onClick"]

import {computed, onMounted, ref, Ref, watch} from 'vue'

interface Props {
  data: Array<any>
  perPage?: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Paginator',
  props: {
    data: {},
    perPage: {}
  },
  emits: ['paginatedData'],
  setup(__props: any, { emit: __emit }) {


const props = __props
const emit = __emit

let page: Ref<number> = ref(1);

const data: Ref<Array<any>> = ref([])

watch(props.data, (newData) => {
  data.value = newData.map((item, index) => {
    return { index: index, value: item };
  })
})

const perPage = props.perPage?? 10

const paginatedData = computed(() =>
    data.value.slice((page.value - 1) * perPage, page.value * perPage)
)

watch(paginatedData, (newData) => {
  const paginated = newData.map((item) => {
    return item.value
  })
  emit('paginatedData', paginated)
})

function nextPage(): void {
  if (page.value !== Math.ceil(data.value.length / perPage)) {
    page.value += 1;
  }
}

function backPage(): void {
  if (page.value !== 1) {
    page.value -= 1;
  }
}

function goToPage(numPage: number): void {
  page.value = numPage
}

onMounted(() => {
  data.value = props.data.map((item, index) => {
    return { index: index, value: item };
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", { class: "page-item" }, [
          _createElementVNode("a", {
            class: "page-link cursor-pointer",
            onClick: backPage,
            tabindex: "-1"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("em", { class: "fa fa-angle-left" }, null, -1),
            _createElementVNode("span", { class: "sr-only" }, "Previous", -1)
          ]))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(data.value.length / _unref(perPage)), (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item,
            class: _normalizeClass(["page-item cursor-pointer", { active: _unref(page) === item }])
          }, [
            _createElementVNode("a", {
              class: "page-link",
              onClick: ($event: any) => (goToPage(item))
            }, [
              _createTextVNode(_toDisplayString(item) + " ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "(current)", -1))
            ], 8, _hoisted_4)
          ], 2))
        }), 128)),
        _createElementVNode("li", { class: "page-item cursor-pointer" }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: nextPage
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("em", { class: "fa fa-angle-right" }, null, -1),
            _createElementVNode("span", { class: "sr-only" }, "Next", -1)
          ]))
        ])
      ])
    ])
  ]))
}
}

})