import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-header pb-0" }
const _hoisted_3 = { class: "modal-header pb-0" }
const _hoisted_4 = { class: "card-body px-0 pt-0 pb-2" }
const _hoisted_5 = { class: "table-responsive p-0" }
const _hoisted_6 = { class: "table align-items-center mb-0" }
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_8 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_9 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_10 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_11 = { class: "d-flex px-2 py-1" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_14 = { class: "mb-0 text-sm" }
const _hoisted_15 = { class: "text-xs text-secondary mb-0" }
const _hoisted_16 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_17 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_18 = { class: "align-middle text-center text-sm" }
const _hoisted_19 = { class: "align-middle text-center" }
const _hoisted_20 = { class: "text-secondary text-xs font-weight-bold" }
const _hoisted_21 = { class: "align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('common.models.users', 2)), 1),
        _createVNode(_component_router_link, {
          to: { name: 'users.create'},
          tag: "a",
          class: "btn btn-sm btn-primary btn-rounded",
          "data-original-title": "User Create"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("em", { class: "fas fa-plus" }, null, -1)
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("caption", { hidden: "" }, null, -1)),
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('users.fields.name')), 1),
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('users.fields.phone')), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('users.fields.role')), 1),
              _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('common.fields.status')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: user.id
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: user.photoUrl,
                        class: "avatar avatar-sm me-3",
                        alt: "Profile image"
                      }, null, 8, _hoisted_12)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("h6", _hoisted_14, _toDisplayString(user.name), 1),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(user.email), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(user.phone), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t(user.roles.admin ? 'users.fields.admin' : 'users.fields.operator')), 1)
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["badge badge-sm bg-gradient-success", user.enabled_at? 'bg-gradient-success' : 'bg-gradient-danger'])
                  }, _toDisplayString(_ctx.$t(user.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 3)
                ]),
                _createElementVNode("td", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.format(user.created_at)), 1)
                ]),
                _createElementVNode("td", _hoisted_21, [
                  _createVNode(_component_router_link, {
                    to: { name: 'users.edit', params: {id: user.id}},
                    tag: "a",
                    class: "btn btn-sm btn-dark btn-rounded px-2 py-1 mb-1",
                    "data-original-title": "Edit user"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("em", { class: "fas fa-pencil" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}