import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "container-fluid pb-4" }
const _hoisted_2 = { class: "card w-75 mx-auto" }
const _hoisted_3 = { class: "card-header text-center text-capitalize" }
const _hoisted_4 = { class: "card-body pt-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "card-header text-center text-capitalize" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-label" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_15 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_18 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = ["placeholder"]
const _hoisted_21 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-sm-3" }
const _hoisted_25 = ["value"]
const _hoisted_26 = { class: "col-sm-9" }
const _hoisted_27 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_28 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_29 = { class: "form-check form-switch" }
const _hoisted_30 = { class: "form-check-label" }
const _hoisted_31 = { class: "col-md-6" }
const _hoisted_32 = { class: "card-header text-center text-capitalize" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = { class: "form-label" }
const _hoisted_35 = { class: "form-group" }
const _hoisted_36 = ["placeholder"]
const _hoisted_37 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_38 = { class: "form-group" }
const _hoisted_39 = ["value"]
const _hoisted_40 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_41 = { class: "form-group" }
const _hoisted_42 = ["placeholder"]
const _hoisted_43 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_44 = { class: "row" }
const _hoisted_45 = { class: "form-group col-sm-8" }
const _hoisted_46 = ["value"]
const _hoisted_47 = { class: "form-group col-sm-4" }
const _hoisted_48 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_49 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_50 = { class: "row" }
const _hoisted_51 = { class: "form-group col-sm-6" }
const _hoisted_52 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_53 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_54 = { class: "form-group col-sm-6" }
const _hoisted_55 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_56 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_57 = { class: "card-footer text-end" }
const _hoisted_58 = {
  class: "btn btn-info",
  type: "submit"
}

import CustomValidator from '@/assets/validatiions/validators'
import StorageService from '@/services/StorageService'
import {ErrorMessage, Field, Form, FormActions} from 'vee-validate'
import {string, date, mixed, object, ObjectSchema} from 'yup'
import dayjs from 'dayjs'
import Driver from '@/models/Driver'
import DriverRepository from '@/repositories/DriverRepository'
import {Constants} from '@/constants/Constants'
import ToastService from '@/services/ToastService'
import {DriverInterface} from '@/types/DriverInterface'
import i18n from '@/plugins/i18n'
import {onMounted, ref, Ref, watch} from 'vue'
import {useLoadingState} from '@/services/stores/LoadingState'
import router from '@/router'
import {useDriversStore} from '@/services/stores/DriversStore'
import {StrHelper} from '@/helpers/StrHelper'
import DateHelper from '@/helpers/DateHelper'
import {useSettingsStore} from "@/services/stores/SettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'Create',
  setup(__props) {

const driver: Ref<Driver> = ref(new Driver)
const password: Ref<string> = ref('')
const imageDriver: Ref<File[]> = ref([])
const imageVehicle: Ref<File[]> = ref([])
const color: Ref<string> = ref(Constants.COLORS[0].hex)
const types: Array<any> = Constants.DOC_TYPES
const {setLoading} = useLoadingState()
const {addDriver} = useDriversStore()
const {branchSelected} = useSettingsStore()
const schema: ObjectSchema<any> = object().shape({
  name: string().required().min(3),
  email: string().required().email(),
  password: string().required().min(6),
  phone: string().required().min(8),
  docType: mixed().oneOf(Constants.DOC_TYPES).required(),
  document: string().required().min(6).max(10),
  brand: string().required().min(3),
  plate: string().required().min(3),
  model: string().required().min(3),
  color: string().matches(new RegExp(/^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/)).required(),
  soat_exp: date().required(),
  tec_exp: date().required(),
  photoUrl: CustomValidator.isImage(i18n.global.t('validations.image'), i18n.global.t('validations.size')).required(),
  photoVehicleUrl: CustomValidator.isImage(i18n.global.t('validations.image'), i18n.global.t('validations.size')).required()
})

onMounted(() => {
  driver.value.docType = Constants.DOC_TYPE_CC
  driver.value.vehicle.model = DateHelper.arrayYears()[0].toString()
})

watch(driver, (newDriver) => {
  driver.value.name = StrHelper.toCamelCase(newDriver.name ?? '')
  driver.value.vehicle.brand = StrHelper.toCamelCase(newDriver.vehicle?.brand ?? '')
  driver.value.vehicle.model = StrHelper.toCamelCase(newDriver.vehicle?.model ?? '')
  driver.value.vehicle.plate = StrHelper.formatPlate(newDriver.vehicle?.plate ?? '')
  driver.value.phone = StrHelper.formatNumber(newDriver.phone ?? '')
}, {deep: true})

watch(color, (newColor) => {
  driver.value.vehicle.color = Constants.COLORS.find(c => c.hex == newColor)?? Constants.COLORS[0]
})

function uploadImg(path: string, image: File): Promise<string> {
  const reference = StorageService.getStorageReference(path, driver.value.id ?? '', image.name)
  return StorageService.uploadFile(reference, image)
}

function createDriver(_values: DriverInterface, event: FormActions<any>): void {
  setLoading(true)
  driver.value.vehicle.soat_exp = dayjs(driver.value.vehicle.soat_exp).unix()
  driver.value.vehicle.tec_exp = dayjs(driver.value.vehicle.tec_exp).unix()
  driver.value.phone = branchSelected?.calling_code + driver.value.phone
  DriverRepository.create(driver.value, password.value).then((id) => {
    driver.value.id = id
    uploadImg(StorageService.driverPath, imageDriver.value[0]).then(url => {
      driver.value.photoUrl = url
      uploadImg(StorageService.vehiclePath, imageVehicle.value[0]).then(urlPhotoVehicle => {
        setLoading(false)
        driver.value.vehicle.photoUrl = urlPhotoVehicle
        addDriver(driver.value)
        DriverRepository.update(driver.value)
        ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.created'))
        event.resetForm()
        router.push({name: 'drivers.index'})
      })
    })
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function onEnable(e: Event): void {
  const target = e.target as HTMLInputElement
  driver.value.enabled_at = target.checked ? dayjs().unix() : 0
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Form), {
      onSubmit: createDriver,
      "validation-schema": _unref(schema)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create_driver')), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('drivers.placeholders.photo')), 1),
                  _createVNode(_unref(Field), {
                    name: "photoUrl",
                    class: "form-control form-control-sm",
                    type: "file",
                    accept: "image/*",
                    multiple: "",
                    modelValue: imageDriver.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((imageDriver).value = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "photoUrl",
                    class: "is-invalid"
                  })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.name')), 1),
                  _createVNode(_unref(Field), {
                    name: "name",
                    type: "text",
                    modelValue: driver.value.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((driver.value.name) = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control form-control-sm",
                        id: "name",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((driver.value.name) = $event)),
                        placeholder: _ctx.$t('common.placeholders.name'),
                        "aria-label": "Name",
                        "aria-describedby": "name-addon"
                      }, field), null, 16, _hoisted_11), [
                        [_vModelDynamic, driver.value.name]
                      ]),
                      (errorMessage && meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.email')), 1),
                  _createVNode(_unref(Field), {
                    name: "email",
                    type: "email",
                    modelValue: driver.value.email,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((driver.value.email) = $event)),
                    autocomplete: "off"
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('common.placeholders.email'),
                        id: "email",
                        "aria-label": "Email",
                        "aria-describedby": "email-addon"
                      }, field), null, 16, _hoisted_14), [
                        [_vModelDynamic, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.password')), 1),
                  _createVNode(_unref(Field), {
                    name: "password",
                    type: "password",
                    modelValue: password.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('users.fields.password'),
                        id: "password",
                        "aria-label": "Password",
                        "aria-describedby": "password-addon"
                      }, field), null, 16, _hoisted_17), [
                        [_vModelDynamic, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.phone')), 1),
                  _createVNode(_unref(Field), {
                    name: "phone",
                    type: "phone",
                    modelValue: driver.value.phone,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((driver.value.phone) = $event))
                  }, {
                    default: _withCtx(({ errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((driver.value.phone) = $event)),
                        placeholder: _ctx.$t('common.placeholders.phone'),
                        id: "phone",
                        "aria-label": "Phone",
                        "aria-describedby": "phone-addon"
                      }, null, 8, _hoisted_20), [
                        [_vModelText, driver.value.phone]
                      ]),
                      (errorMessage && !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.doc_type')), 1),
                      _createVNode(_unref(Field), {
                        name: "docType",
                        class: "form-select form-select-sm",
                        id: "doc_type",
                        as: "select",
                        modelValue: driver.value.docType,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((driver.value.docType) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(types), (type, key) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: key,
                              value: type
                            }, _toDisplayString(type), 9, _hoisted_25))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.document')), 1),
                      _createVNode(_unref(Field), {
                        name: "document",
                        modelValue: driver.value.document,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((driver.value.document) = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control form-control-sm",
                            id: "document",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('common.placeholders.comment'),
                            "aria-label": "Document",
                            "aria-describedby": "doc-addon"
                          }, field, { autocomplete: "none" }), null, 16, _hoisted_27), [
                            [_vModelDynamic, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    name: "enabled_at",
                    type: "checkbox",
                    id: "enableDriver",
                    onChange: onEnable
                  }, null, 32),
                  _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t(driver.value.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 1),
                  _createVNode(_unref(ErrorMessage), { name: "enabled_at" })
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create_vehicle')), 1)
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t('drivers.placeholders.photo_vehicle')), 1),
                  _createVNode(_unref(Field), {
                    name: "photoVehicleUrl",
                    class: "form-control form-control-sm",
                    type: "file",
                    accept: "image/*",
                    multiple: "",
                    modelValue: imageVehicle.value,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((imageVehicle).value = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "photoVehicleUrl",
                    class: "is-invalid"
                  })
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.brand')), 1),
                  _createVNode(_unref(Field), {
                    name: "brand",
                    type: "text",
                    modelValue: driver.value.vehicle.brand,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((driver.value.vehicle.brand) = $event))
                  }, {
                    default: _withCtx(({ errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((driver.value.vehicle.brand) = $event)),
                        placeholder: _ctx.$t('drivers.placeholders.brand'),
                        id: "brand",
                        "aria-label": "Brand",
                        "aria-describedby": "brand-addon",
                        autocomplete: "none"
                      }, null, 8, _hoisted_36), [
                        [_vModelText, driver.value.vehicle.brand]
                      ]),
                      (errorMessage && meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.model')), 1),
                  _createVNode(_unref(Field), {
                    name: "model",
                    as: "select",
                    class: "form-select form-select-sm",
                    modelValue: driver.value.vehicle.model,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((driver.value.vehicle.model) = $event))
                  }, {
                    default: _withCtx(({ errorMessage, meta }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(DateHelper).arrayYears(), (year, key) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: key,
                          value: year
                        }, _toDisplayString(year), 9, _hoisted_39))
                      }), 128)),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.plate')), 1),
                  _createVNode(_unref(Field), {
                    name: "plate",
                    type: "text",
                    modelValue: driver.value.vehicle.plate,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((driver.value.vehicle.plate) = $event))
                  }, {
                    default: _withCtx(({ errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((driver.value.vehicle.plate) = $event)),
                        placeholder: _ctx.$t('drivers.placeholders.plate'),
                        id: "plate",
                        "aria-label": "Plate",
                        "aria-describedby": "plate-addon",
                        autocomplete: "none"
                      }, null, 8, _hoisted_42), [
                        [_vModelText, driver.value.vehicle.plate]
                      ]),
                      (errorMessage && meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.placeholders.color')), 1),
                    _withDirectives(_createElementVNode("select", {
                      name: "colorObj",
                      class: "form-select form-select-sm",
                      id: "color",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((color).value = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(Constants).COLORS, (color, key) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: key,
                          value: color.hex
                        }, _toDisplayString(_ctx.$t('common.colors.' + color.name)), 9, _hoisted_46))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, color.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.color')), 1),
                    _createVNode(_unref(Field), {
                      name: "color",
                      modelValue: driver.value.vehicle.color.hex,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((driver.value.vehicle.color.hex) = $event))
                    }, {
                      default: _withCtx(({ field, errorMessage, meta }) => [
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          name: "color",
                          class: "form-control form-control-sm p-0",
                          type: "color",
                          disabled: "",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('drivers.placeholders.color'),
                          "aria-label": "Color",
                          "aria-describedby": "color-addon"
                        }, field, { autocomplete: "none" }), null, 16, _hoisted_48), [
                          [_vModelText, field.value]
                        ]),
                        (errorMessage || !meta.dirty)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.soat_exp')), 1),
                    _createVNode(_unref(Field), {
                      name: "soat_exp",
                      type: "date",
                      modelValue: driver.value.vehicle.soat_exp,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((driver.value.vehicle.soat_exp) = $event))
                    }, {
                      default: _withCtx(({ field, errorMessage, meta }) => [
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          class: "form-control form-control-sm",
                          type: "date",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('drivers.placeholders.soat_exp'),
                          id: "soat_exp",
                          "aria-label": "Soat_exp",
                          "aria-describedby": "soat_exp-addon"
                        }, field, { autocomplete: "none" }), null, 16, _hoisted_52), [
                          [_vModelText, field.value]
                        ]),
                        (errorMessage || !meta.dirty)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.tec_exp')), 1),
                    _createVNode(_unref(Field), {
                      name: "tec_exp",
                      type: "date",
                      modelValue: driver.value.vehicle.tec_exp,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((driver.value.vehicle.tec_exp) = $event))
                    }, {
                      default: _withCtx(({ field, errorMessage, meta }) => [
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          class: "form-control form-control-sm",
                          type: "date",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('drivers.placeholders.tec_exp'),
                          id: "tec_exp",
                          "aria-label": "Pec_exp",
                          "aria-describedby": "tec_exp-addon"
                        }, field, { autocomplete: "none" }), null, 16, _hoisted_55), [
                          [_vModelText, field.value]
                        ]),
                        (errorMessage || !meta.dirty)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ])
              ])
            ])
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("button", _hoisted_58, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema"])
  ]))
}
}

})