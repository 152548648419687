export enum MessageTypes {
  TEXT = 'text',
  AUDIO = 'audio',
  VOICE = 'ptt',
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
  STICKER = 'sticker',
  LOCATION = 'location',
  CONTACT_CARD = 'vcard',
  CONTACT_CARD_MULTI = 'multi_vcard',
  REVOKED = 'revoked',
  ORDER = 'order',
  PRODUCT = 'product',
  PAYMENT = 'payment',
  UNKNOWN = 'unknown',
  GROUP_INVITE = 'groups_v4_invite',
  LIST = 'list',
  LIST_RESPONSE = 'list_response',
  BUTTONS_RESPONSE = 'buttons_response',
  BROADCAST_NOTIFICATION = 'broadcast_notification',
  CALL_LOG = 'call_log',
  CIPHERTEXT = 'ciphertext',
  DEBUG = 'debug',
  E2E_NOTIFICATION = 'e2e_notification',
  GP2 = 'gp2',
  GROUP_NOTIFICATION = 'group_notification',
  HSM = 'hsm',
  INTERACTIVE = 'interactive',
  NATIVE_FLOW = 'native_flow',
  NOTIFICATION = 'notification',
  NOTIFICATION_TEMPLATE = 'notification_template',
  OVERSIZED = 'oversized',
  PROTOCOL = 'protocol',
  REACTION = 'reaction',
  TEMPLATE_BUTTON_REPLY = 'template_button_reply',
  POLL_CREATION = 'poll_creation',
}
