import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "container-fluid pb-4" }
const _hoisted_2 = { class: "card w-75 mx-auto" }
const _hoisted_3 = { class: "card-header text-center text-capitalize" }
const _hoisted_4 = { class: "card-body pt-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "card-header p-0 mx-3 mt-3 z-index-1" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col-md-7" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-label" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_14 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_20 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_23 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = { class: "form-check mb-4 d-inline-block ms-4" }
const _hoisted_26 = ["checked"]
const _hoisted_27 = { class: "custom-control-label" }
const _hoisted_28 = { class: "form-check d-inline-block ms-3" }
const _hoisted_29 = ["checked"]
const _hoisted_30 = { class: "custom-control-label" }
const _hoisted_31 = { class: "form-check form-switch" }
const _hoisted_32 = ["checked"]
const _hoisted_33 = { class: "form-check-label" }
const _hoisted_34 = { class: "card-footer text-end" }
const _hoisted_35 = {
  class: "btn btn-primary",
  type: "submit"
}

import UserRepository from '@/repositories/UserRepository'
import User from '@/models/User'
import {ErrorMessage, Field, Form, FormActions} from 'vee-validate'
import * as yup from 'yup'
import CustomValidator from '@/assets/validatiions/validators'
import StorageService from '@/services/StorageService'
import i18n from '@/plugins/i18n'
import {onMounted, ref, Ref} from 'vue'
import dayjs from 'dayjs'
import ToastService from '@/services/ToastService'
import {UserInterface} from '@/types/UserInterface'
import {useStorage} from '@/services/stores/Storage'
import {storeToRefs} from 'pinia'
import router from '@/router'
import {useLoadingState} from '@/services/stores/LoadingState'
import {useSettingsStore} from "@/services/stores/SettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'Create',
  setup(__props) {

const storage = useStorage()
const {photoUrl} = storeToRefs(storage)


const user: Ref<User> = ref(new User)
const image: Ref<File[]> = ref([])
const password: Ref<string> = ref('')
const {setLoading} = useLoadingState()
const { branchSelected } = useSettingsStore()

const schema = yup.object().shape({
  photoUrl: CustomValidator.isImage(i18n.global.t('validations.image'), i18n.global.t('validations.size')).required(),
  name: yup.string().required().min(3),
  email: yup.string().required().email(),
  phone: yup.string().required().min(8),
  password: yup.string().required().min(6),
})

function createUser(_values: UserInterface, event: FormActions<any>): void {
  setLoading(true)
  user.value.phone = branchSelected.calling_code + user.value.phone
  UserRepository.create(user.value, password.value).then((id) => {
    user.value.id = id
    const reference = StorageService.getStorageReference(StorageService.profilePath, user.value.id ?? '', image.value[0]?.name)
    StorageService.uploadFile(reference, image.value[0]).then(url => {
      user.value.photoUrl = url
      event.resetForm()
      UserRepository.update(user.value).then(() => {
        setLoading(false)
        ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.created'))
        event.resetForm()
        user.value.photoUrl = photoUrl.value
        image.value = []
        router.push({name: 'users.index'})
      })
    })
  }).catch(e => {
    setLoading(false)
    ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function onEnable(e: Event): void {
  const target = e.target as HTMLInputElement
  user.value.enabled_at = target.checked ? dayjs().unix() : 0
}
function assignRole(e: Event): void {
  const target = e.target as HTMLInputElement
  if (user.value.roles) {
    if (target.value === 'operator') {
      user.value.roles.operator = target.checked
    } else {
      user.value.roles.admin = target.checked
    }
  }
}

onMounted(() => {
  storage.getDefaultPhotoUrl().then(() => {
    user.value.photoUrl = photoUrl.value
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Form), {
      onSubmit: createUser,
      "validation-schema": _unref(schema)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('users.forms.edit')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: user.value.photoUrl,
                    class: "img-fluid border-radius-lg",
                    alt: "profile_photo"
                  }, null, 8, _hoisted_8)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('drivers.placeholders.photo')), 1),
                  _createVNode(_unref(Field), {
                    name: "photoUrl",
                    class: "form-control form-control-sm",
                    type: "file",
                    accept: "image/*",
                    multiple: "",
                    modelValue: image.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((image).value = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "photoUrl",
                    class: "is-invalid"
                  })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.name')), 1),
                  _createVNode(_unref(Field), {
                    name: "name",
                    type: "text",
                    modelValue: user.value.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.value.name) = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('common.placeholders.name'),
                        id: "name",
                        "aria-label": "Name",
                        "aria-describedby": "name-addon"
                      }, field), null, 16, _hoisted_13), [
                        [_vModelDynamic, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.email')), 1),
                  _createVNode(_unref(Field), {
                    name: "email",
                    type: "email",
                    modelValue: user.value.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((user.value.email) = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('common.placeholders.email'),
                        id: "email",
                        "aria-label": "Email",
                        "aria-describedby": "email-addon"
                      }, field), null, 16, _hoisted_16), [
                        [_vModelDynamic, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.password')), 1),
                  _createVNode(_unref(Field), {
                    name: "password",
                    type: "password",
                    modelValue: password.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control form-control-sm",
                        type: "password",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('users.fields.password'),
                        id: "password",
                        "aria-label": "Password",
                        "aria-describedby": "password-addon"
                      }, field), null, 16, _hoisted_19), [
                        [_vModelText, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.phone')), 1),
                  _createVNode(_unref(Field), {
                    name: "phone",
                    type: "phone",
                    modelValue: user.value.phone,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((user.value.phone) = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('common.placeholders.phone'),
                        id: "phone",
                        "aria-label": "Phone",
                        "aria-describedby": "phone-addon"
                      }, field), null, 16, _hoisted_22), [
                        [_vModelDynamic, field.value]
                      ]),
                      (errorMessage || !meta.dirty)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.role')) + ":", 1),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      onChange: assignRole,
                      name: "role",
                      id: "operator",
                      value: 'operator',
                      checked: user.value.roles.operator
                    }, null, 40, _hoisted_26),
                    _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('users.fields.operator')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      name: "role",
                      onChange: assignRole,
                      id: "admin",
                      value: 'admin',
                      checked: user.value.roles.admin
                    }, null, 40, _hoisted_29),
                    _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('users.fields.admin')), 1)
                  ]),
                  _createVNode(_unref(ErrorMessage), { name: "role" })
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    name: "enable",
                    type: "checkbox",
                    id: "enableUser",
                    checked: user.value.isEnabled(),
                    onChange: onEnable
                  }, null, 40, _hoisted_32),
                  _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t(user.value.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 1),
                  _createVNode(_unref(ErrorMessage), { name: "enable" })
                ])
              ])
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("button", _hoisted_35, _toDisplayString(_ctx.$t('common.actions.create')), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema"])
  ]))
}
}

})