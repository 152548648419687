import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row row-cols-2 w-100" }
const _hoisted_2 = { class: "col-9" }
const _hoisted_3 = { class: "badge bg-secondary" }
const _hoisted_4 = { class: "form-group col-3" }
const _hoisted_5 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_7 = { class: "row card-body min-vh-75" }

import {onBeforeMount, onBeforeUnmount, ref, Ref, watch} from 'vue'
import {useDriversStore} from '@/services/stores/DriversStore'
import {Field} from 'vee-validate'
import Map from '@/components/maps/Map.vue'
import {PlaceInterface} from '@/types/PlaceInterface'


export default /*@__PURE__*/_defineComponent({
  __name: 'DriverMap',
  setup(__props) {

const {connectedDrivers, getOnlineDrivers, offOnlineDrivers} = useDriversStore()
const searchDriver: Ref<string> = ref('')
const filteredDrivers: Ref<Array<PlaceInterface>> = ref([])
const icon: Ref<string> = ref(process.env.VUE_APP_DRIVER_LOC_IMAGE_URL as string)
let filtering = false

watch(searchDriver, (plate) => {
  if (plate.length > 0) {
    filtering = true
    const filtered = connectedDrivers.filter(place => place.name.toLowerCase().includes(plate.toLowerCase()))
    filteredDrivers.value.splice(0, filteredDrivers.value.length)
    filtered.forEach(driver => filteredDrivers.value.push(driver))
  } else {
    filtering = false
    connectedDrivers.forEach(driver => filteredDrivers.value.push(driver))
  }
})

watch(connectedDrivers, (newConnectedDrivers) => {
  if (!filtering) {
    if (filteredDrivers.value.length <= newConnectedDrivers.length) {
      const intersections = newConnectedDrivers.filter(driver => filteredDrivers.value.indexOf(driver) === -1)
      intersections.forEach(driver => { 
        const currents = filteredDrivers.value.filter(dri => dri.key === driver.key)
        if (currents.length === 1) {
          const index = filteredDrivers.value.indexOf(currents[0])
          filteredDrivers.value[index] = driver
        } else {
          filteredDrivers.value.push(driver)
        }
      })
      } else {
      const intersections = filteredDrivers.value.filter(driver => newConnectedDrivers.indexOf(driver) === -1)
      intersections.forEach(driver => {
        const currents = filteredDrivers.value.filter(dri => dri.key === driver.key)
        currents.forEach(current => {
          const index = filteredDrivers.value.indexOf(current)
          filteredDrivers.value.splice(index, 1)
        })
      })      
      }
    }
})

onBeforeUnmount(() => {
  offOnlineDrivers()
})

onBeforeMount(() => {
  getOnlineDrivers()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('common.models.drivers_connected')) + " ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(filteredDrivers.value.length), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(Field), {
          name: "driver",
          type: "search",
          modelValue: searchDriver.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchDriver).value = $event))
        }, {
          default: _withCtx(({ field, errorMessage, meta }) => [
            _withDirectives(_createElementVNode("input", _mergeProps({
              class: "form-control form-control-sm me-2",
              type: "search",
              "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
              placeholder: _ctx.$t('common.placeholders.search')
            }, field, { autocomplete: "off" }), null, 16, _hoisted_5), [
              [_vModelText, field.value]
            ]),
            (errorMessage || !meta.dirty)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(errorMessage), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(Map, {
        places: filteredDrivers.value,
        icon: icon.value
      }, null, 8, ["places", "icon"])
    ])
  ], 64))
}
}

})